import { Component, DestroyRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CustomMediaOptions, PlayerOptions } from '@models/PlayerOptions';
import { VideoService } from '@services/video.service';

interface Envs {
  title: string;
  env: string;
}

@Component({
  selector: 'custom-media',
  templateUrl: './custom-media.component.html',
  styleUrls: ['./custom-media.component.css'],
  providers: [],
})
export class CustomMediaComponent implements OnInit, OnChanges {
  @Output() submitOverride = new EventEmitter<CustomMediaOptions>();
  @Input() mediaTitle = 'Media Id / URL';
  @Input() boundOpts: PlayerOptions | undefined;

  customOptionsFormGroup: FormGroup = new FormGroup({
    overrideEnabled: new FormControl(false, Validators.requiredTrue),
    customMediaId: new FormControl({ value: '', disabled: true }, Validators.required),
    customEnv: new FormControl({ value: '', disabled: true }, Validators.required),
    customCdn: new FormControl({ value: '', disabled: true }),
    customAdProfile: new FormControl({ value: '', disabled: true }),
  });
  customOptionsValues = {};
  envs: Envs[] = [
    { title: 'Production', env: 'prod' },
    { title: 'ITE', env: 'ite' },
  ];
  cdnOptions: any[] = [];
  private destroyRef = inject(DestroyRef);

  constructor(
    private readonly eventService: VideoService,
    private readonly router: Router,
  ) {
    this.customOptionsFormGroup.get('overrideEnabled')?.valueChanges.subscribe((enabled) => {
      if (enabled) {
        this.customOptionsFormGroup.get('customMediaId')?.enable();
        this.customOptionsFormGroup.get('customEnv')?.enable();
        this.customOptionsFormGroup.get('customCdn')?.enable();
        this.customOptionsFormGroup.get('customAdProfile')?.enable();
      } else {
        this.customOptionsFormGroup.get('customMediaId')?.disable();
        this.customOptionsFormGroup.get('customEnv')?.disable();
        this.customOptionsFormGroup.get('customCdn')?.disable();
        this.customOptionsFormGroup.get('customAdProfile')?.disable();
      }
    });
  }

  resetOverride() {
    this.customOptionsFormGroup.reset({
      ...this.customOptionsValues,
      overrideEnabled: false,
    });
  }

  playOverride() {
    const { customMediaId, customAdProfile, customEnv, customCdn } = this.customOptionsFormGroup.value;
    this.submitOverride.emit({
      mediaId: customMediaId,
      adProfile: customAdProfile,
      env: customEnv,
      cdn: customCdn,
    });
  }

  ngOnInit() {
    this.eventService
      .getCDNOptions()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((cdnConfig) => {
        this.cdnOptions = cdnConfig.cdns.map((m: any) => {
          return {
            label: m.label,
            value: m.value,
            disabled: m.enabled === false,
          };
        });
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log('»»» ngOnChanges', changes);
    const nv = changes['boundOpts']?.currentValue;
    if (nv) {
      const { mediaId = '', env = '', cdn = '', adProfile = '' } = nv;
      const overrideEnabled = this.customOptionsFormGroup.value.overrideEnabled || false;

      this.customOptionsValues = {
        overrideEnabled,
        customMediaId: mediaId,
        customEnv: env,
        customCdn: cdn,
        customAdProfile: adProfile,
      };
      this.customOptionsFormGroup.setValue({
        ...this.customOptionsValues,
      });
    }
  }
}
