import { Injectable } from '@angular/core';
import { Observable, timer } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OperateGameSignalTickService {
  private readonly interval = 2000;
  readonly timer$: Observable<number> = timer(0, this.interval);
}
