/* eslint-disable @typescript-eslint/no-unused-vars */
import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { UserProfile } from '@models/UserProfile';
import { OktaAuthStateService } from '@okta/okta-angular';
import { ConfigService } from '@services/config.service';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { environment as env } from '../../../../environments/environment';

const logTag = '[ops-guard]';
const logMsg = console.debug.bind(console, logTag);
const logErr = console.error.bind(console, logTag);

@Injectable({
  providedIn: 'root',
})
export class OpsGuard implements CanLoad, CanActivate {
  private hasOpsRole$: Observable<boolean> = new Observable();
  private currentProfile$: Observable<UserProfile> = new Observable();
  private destroyRef = inject(DestroyRef);

  constructor(
    private readonly stateService: OktaAuthStateService,
    private readonly router: Router,
    private readonly configService: ConfigService,
  ) {
    this.hasOpsRole$ = this.stateService.hasAnyGroups(env.opsRole).pipe(takeUntilDestroyed(this.destroyRef));
    this.currentProfile$ = this.configService.CurrentProfile$.pipe(takeUntilDestroyed(this.destroyRef));
  }

  hasBrandRole(brand: string): Observable<boolean> {
    return this.currentProfile$.pipe(
      map((profile: UserProfile) => {
        // logMsg(`[hasBrandRole(${brand})] profile`, profile);

        // bail early if we're in ops but we're not in a brand section, i.e. we're on a landing page or other "public" page
        if (brand === '') {
          // logMsg(`[hasBrandRole(${brand})] allowing because there is no specified brand, presuming a generic Ops page`);
          return true;
        }

        // otherwise check that the brand param in the URL exists for this user
        const hasOpsProfile = profile.opsProfile != null;
        const hasGlobalRole = hasOpsProfile && (profile.opsProfile.globalRole ?? '') !== '';
        const hasOpsRoleForBrand = hasOpsProfile && (profile.opsProfile.brandRoles || []).some((p: any) => p.brandCode === brand);
        // logMsg(`[hasBrandRole(${brand})]`, { hasOpsProfile, hasGlobalRole, hasOpsRoleForBrand });
        return hasGlobalRole || hasOpsRoleForBrand;
      }),
    );
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    // if `canActivate` comes after `canLoad`, can we assume the `hasOpsRole$` check has already been made?
    // logMsg('[canActivate]');

    if (!route.params.brand) {
      // apparently, `canActivate` is called multiple times? first, without a `brand`
      // logMsg('[canActivate] no brand param, no guard needed');
      return true;
    }

    return this.hasBrandRole(route.params.brand).pipe(
      take(1),
      // tap((_) => logMsg('[canActivate] hasBrandRole() result', _)),
      map((hasBrandRole) => {
        if (hasBrandRole) {
          // logMsg('[canActivate] found brand role, allowing access');
          return true;
        }

        // otherwise ...
        // logMsg('[canActivate] no brand role found, denying access');
        this.router.navigate(['/']);
        return false;
      }),
    );
  }

  canLoad(route: Route, segments: UrlSegment[]): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    // logMsg('[canLoad]');
    // `canLoad` fires before `canActivate`, and `Route` has no knowledge of `params`
    // so, just block on lack of "ops role" -- lack of Okta existence?
    return this.hasOpsRole$.pipe(
      map((hasOpsRole) => {
        if (hasOpsRole) {
          // logMsg('[canLoad] found Ops role, allowing load');
          return true;
        }

        // otherwise ...
        // logMsg('[canLoad] no Ops role found, denying load');
        this.router.navigate(['/']);
        return false;
      }),
    );
  }
}
