import { EventSourceType } from './Metadata';
import { TemplateCustomization } from './OpsTemplate';

export enum OpsEventStatus {
  NEW = 1, // coord can create a checklist
  GAMES_SELECTED = 2, // created checklist
  METADATA_VALIDATED = 3, // 100% completed metadata
  OPERABLE = 4, // coord has clicked Open to Operate
  COMPLETED = 5, // coord has clicked Close Event
}

// TODO: Add another enum for Report-Ready, which will be a Coordinator-only state?

export enum ChecklistCompleteOptions {
  UNANSWERED = '',
  YES = 'Y',
  NO = 'N',
  NOTAPPLICABLE = 'N/A',
}

export enum OpsChecklistPhaseStatus {
  NOT_YET_STARTED = 1,
  STARTED = 2,
  COMPLETE = 3,
}

export interface CheckListUpdateItem {
  complete: string;
  notes: string;
  stage: number;
  step: number;
  program: number;
}

export interface OpsCheckpointNote {
  name: string;
  note: string;
}
export interface OpsBrandEvent {
  id?: string;
  brand: string;
  title: string;
  eventWindow: {
    start: Date;
    end: Date;
  };
  sourceType: EventSourceType;
  eventDays: OpsEventDay[];
  eventTemplate?: string;
  createdAt: Date;
  updatedAt?: Date;
}

export interface OpsBrand {
  brandName: string;
  brandCode: string;
  brandUsers: OpsEventUser[] | [];
}

export interface OpsEventUser {
  _id: string;
  name: string;
  email: string;
  initials: string;
  brandRoles: any[];
}

export interface OpsEventCheckpoint {
  teams: { name: string; status: number }[];
  metrics: { name: string; value: string }[];
}

export interface OpsEventCheckpointEntry {
  timestamp: Date;
  checkBy: string;
  checkpoint: OpsEventCheckpoint;
  entryId: number;
}

export interface OpsEventDay {
  id?: string;
  title: string;
  startDate?: Date;
  status: OpsEventStatus;
  date: string;
  dayNumber: number;
  team?: {
    eventCoordinator?: OpsEventUser;
    backupEventCoordinator?: OpsEventUser;
    eventTeams: { team: OpsBrandEventTeam; members: OpsEventUser[] }[] | [];
  };
  programs: OpsEventDayProgram[] | [];
  incidents: OpsEventDayIncident;
  templateId?: string;
  templateTitle?: string;
  checkpoints: {
    teams: any[];
    metrics: any[];
    notes: {
      teams: OpsCheckpointNote[];
      metrics: OpsCheckpointNote[];
    };
    entries: OpsEventCheckpointEntry[];
  };
  messages?: OpsEventDayProgramMessage[];
}

export interface OpsBrandEventTeam {
  _id: string;
  name: string;
  abbr: string;
  brand: string;
  members: OpsEventUser[];
}

/** ISO8601-like-formatted DateTime string, e.g. `"2024-08-07T22:36:06.122Z"` */
export type ISODateString = string;

export interface OpsChecklistStep {
  instruction: string;
  team: string;
  ordinal: number;
  complete: string;
  checkedBy: OpsEventUser;
  checkedOn: ISODateString;
  notes: string;
  reportable?: boolean;
  reportableLabel?: string;
}

export interface OpsEventDayStage {
  name: string;
  ordinal: number;
  status: OpsChecklistPhaseStatus;
  reportable?: boolean;
  reportableLabel?: string;
  steps: OpsChecklistStep[];
}

export interface OpsEventDayChecklist {
  stages: OpsEventDayStage[];
}

export enum OpsIncidentStatus {
  RED,
  YELLOW,
  GREEN,
}

export interface OpsIncidentHistoryEntry extends Record<string, any> {
  // only the 0th element in `history[]` has a `title`
  title?: string;
  severity?: string;
  timestamp: string;
  user?: string;
  note: string;
  status: OpsIncidentStatus;
}

export interface OpsIncidentEntry {
  id: string;
  programIndex: number;
  timestamp: Date;
  user: string;
  history: OpsIncidentHistoryEntry[];
}

export interface OpsEventDayIncident {
  entries: OpsIncidentEntry[];
}

export interface OpsEventDayProgram {
  _id: string;
  id: string;
  eventId: string;
  title: string;
  brand: string;
  checked: boolean;
  metadata: any;
  customMetadata: any;
  ack: any;
  status: OpsEventDayProgramStatus;
  checklist: OpsEventDayChecklist;
  messages?: OpsEventDayProgramMessage[];
  customization: TemplateCustomization;
}

export enum OpsEventDayProgramStatus {
  INACTIVE = 'inactive',
  ACTIVE = 'active',
}

export interface OpsEventLogEntry {
  timestamp: number;
  committedBy: string;
  actionType: number;
  actionContent: any;
  message: string;
  eventId: string;
  eventDayId: string;
  eventDayProgramId: string;
}
export interface BrandChecklist {
  id: string;
  title: string;
}

export enum OpsCheckpointTeamStatus {
  GREEN = 1,
  YELLOW = 2,
  RED = 3,
  GRAY = 4,
}

export interface OpsEventDayProgramMessage {
  _id?: string; // Optional because new messages won't have an ID until they're saved
  message: string; // The actual message content
  severity: number; // An integer to represent the severity level (e.g., 1 = low, 5 = high)
  lastUpdatedBy: string; // The username or identifier of who last updated the message
  lastUpdatedOn: Date; // The timestamp of the last update
  createdAt: Date; // The timestamp when the message was created; used if message is not updated
  createdBy: string; // The username or identifier of who created the message; used if message is not updated
}
