import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OperateGameSignalTickService } from '@modules/ops_v2/services/operate-game-signal-tick/operate-game-signal-tick.service';
import { Subscription } from 'rxjs';
import spacetime from 'spacetime';

enum TimeSignalState {
  UNACKNOWLEDGED,
  UPCOMING,
  IMMINENT,
  LATE,
  EXPIRED,
  ACKNOWLEDGED,
}

@Component({
  selector: 'template-editor-sample-pill-signal',
  templateUrl: './sample-pill-signal.component.html',
  styleUrls: ['./sample-pill-signal.component.css'],
})
export class SamplePillSignal implements OnInit {
  @Input() acknowledged = false;
  @Input() prop = '';
  @Input() time = '';
  @Input() eventDate = '';
  @Input() disabled?: boolean;
  @Input() focused?: boolean;
  @Input() readonly?: boolean;
  @Output() acknowledgedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private readonly tickService: OperateGameSignalTickService) {}

  ngOnInit() {}

  ngOnDestroy() {}

  onClick(event: Event) {
    if (this.focused !== false && !this.disabled && !this.readonly) {
      this.toggle(event);
    }
  }

  toggle(event: Event) {
    this.acknowledged = !this.acknowledged;
    this.acknowledgedChange.emit(this.acknowledged);
  }

  determineState() {
    // short-circuit if acknowledged
    if (this.acknowledged) return TimeSignalState.ACKNOWLEDGED;
    // early exit if you didn't provide a time
    if (!this.time || !this.eventDate) return TimeSignalState.UNACKNOWLEDGED;
    // otherwise, call the Doctor, cuz we're _time-traveling_, baby!
    const nowS = spacetime.now('America/New_York');
    // this.eventDate should be ET already
    const eventDateS = spacetime(this.eventDate);
    // if now is on the same day of event
    // ASSUME: events are confined within a single day (nothing past midnight)
    if (nowS.format('iso-short') === eventDateS.format('iso-short')) {
      // clone "now" and set the time to what was provided
      const timeS = nowS.time(this.time);
      if (nowS.isAfter(timeS.add(15, 'minutes'))) {
        return TimeSignalState.EXPIRED;
      }
      if (this.prop === 'encoderStartTime' && nowS.isAfter(timeS.add(1, 'minute'))) {
        return TimeSignalState.LATE;
      }
      if (this.prop === 'yospacePollingTime' && nowS.isAfter(timeS.add(5, 'minutes'))) {
        return TimeSignalState.LATE;
      }
      if (nowS.isAfter(timeS.subtract(1, 'minute'))) {
        return TimeSignalState.IMMINENT;
      }
      if (nowS.isAfter(timeS.subtract(15, 'minutes'))) {
        return TimeSignalState.UPCOMING;
      }
    }
    return TimeSignalState.UNACKNOWLEDGED;
  }

  getClassNames() {
    return 'timeSignal';
  }
}
