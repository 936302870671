<p-toast></p-toast>
<p-confirmPopup></p-confirmPopup>
@if (this.brandTemplate) {
  @if (this.templateId) {
    <div #templateEditor class="flex flex-col w-full h-full p-0 m-0">
      <p-steps [model]="steps" [readonly]="false" styleClass="mb-2" [activeIndex]="activeStepIndex" (activeIndexChange)="onActiveIndexChange($event)"></p-steps>
      @switch (this.activeStepIndex) {
        <!-- landing / template name -->
        @case (0) {
          <div class="flex flex-col flex-auto w-full h-full overflow-auto gap-y-4 bg-glass-800">
            <div class="flex flex-col items-center justify-center text-center w-[33%] h-full mt-4 mb-2 m-auto gap-y-6 bg-glass-800">
              <div>
                <label for="templateNameInput">Template Name:</label>
                <div><input id="templateNameInput" type="text" pInputText (change)="this.defile()" [(ngModel)]="brandTemplate.title" placeholder="Please enter a template name" class="w-96" /></div>
              </div>
              <div>
                <label>Runbook Type:</label>
                <div class="flex flex-row items-center gap-6">
                  <p-radioButton label="SLE" name="sle" [value]="sourceChoice.SLE" [(ngModel)]="brandTemplate.sourceType" />
                  <p-radioButton label="LWS" name="lws" [value]="sourceChoice.LWS" [(ngModel)]="brandTemplate.sourceType" />
                </div>
              </div>
              @if (this.templateId && this.templateId !== 'new') {
                <div class="flex flex-row items-center p-2">
                  <p-checkbox label="Active" inputId="activeSwitch" [binary]="true" [(ngModel)]="this.brandTemplate.active" (change)="this.defile()" />
                </div>
              }
            </div>
          </div>
          <!-- button container-->
          <div class="button-container">
            <span></span>
            <p-button class="justify-self-center" label="SAVE CHANGES" [disabled]="this.brandTemplate.title.trim() == ''" (onClick)="saveTemplate()"></p-button>
            <p-button class="justify-self-end" icon="pi pi-step-forward" iconPos="right" label="NEXT STEP" [disabled]="this.brandTemplate.title.trim() == ''" (onClick)="this.activeStepIndex = this.activeStepIndex + 1"></p-button>
          </div>
        }
        <!-- Teams -->
        @case (1) {
          <div class="flex flex-col items-center justify-center flex-auto w-full h-full overflow-auto bg-glass-800">
            <div class="flex justify-center">
              <h2>Select Teams active for this runbook</h2>
            </div>
            <div class="flex flex-col p-2">
              @for (team of this.brandTeams; track team._id; let idx = $index) {
                <p-checkbox class="mt-2" [(ngModel)]="brandTemplate.teams" [value]="team" (onChange)="onChecked($event)" [label]="team.name"></p-checkbox>
              }
            </div>
            <div class="flex justify-center">
              <h2>Note: unselecting a team with tasks/stages assigned will automatically reassign them to Event Coordinator</h2>
            </div>

            <!-- button container-->
          </div>
          <div class="button-container">
            <p-button class="justify-self-start" icon="pi pi-step-backward" label="PREVIOUS STEP" (onClick)="this.activeStepIndex = this.activeStepIndex - 1"></p-button>
            <p-button class="justify-self-center" label="SAVE CHANGES" (onClick)="saveTemplate()"></p-button>
            <p-button class="justify-self-end" icon="pi pi-step-forward" iconPos="right" label="NEXT STEP" (onClick)="this.activeStepIndex = this.activeStepIndex + 1"></p-button>
          </div>
        }
        <!-- Metadata Fields-->
        @case (2) {
          <div class="flex flex-col flex-auto w-full overflow-auto gap-y-4">
            <h2 class="p-2 text-lg font-bold">Select Metadata to use with this runbook:</h2>
            <div class="flex flex-col justify-around flex-auto w-full gap-4 p-2 md:flex-row">
              @for (system of fields; track system; let i = $index) {
                <div class="md:w-1/3">
                  <h2 class="font-bold">{{ system.name }} fields</h2>
                  @for (field of system.fields; track field) {
                    <ops-template-custom-field [field]="field" [overrideName]="getOverrideName(field._id)" [inCollection]="isSelected(field._id)" (selectionChanged)="updateFieldStatus($event)"></ops-template-custom-field>
                  }
                </div>
              }
              <div class="md:w-1/3">
                <h2 class="font-bold">Custom Fields</h2>
                <span>A Custom Field doesn't come from any upstream system and is managed by humans. Name the fields below and choose how they're displayed.</span>
                <br />
                @for (entry of this.brandTemplate.customMetadata.fields || []; track $index) {
                  <div class="w-full p-2 my-1 bg-glass-700">
                    <div class="flex flex-row items-center w-full gap-1 my-1">
                      <div class="w-[25%]">Name</div>
                      <div class="w-[75%]">
                        <input pInputText type="text" required="true" class="w-full" [(ngModel)]="this.brandTemplate.customMetadata.fields[$index].key" />
                      </div>
                    </div>
                    <div class="flex flex-row items-center w-full gap-1 my-1">
                      <div class="w-[25%]">Display as</div>
                      <div class="w-[75%]">
                        <p-dropdown [options]="customOptionsList" styleClass="w-full" [(ngModel)]="this.brandTemplate.customMetadata.fields[$index].display"></p-dropdown>
                      </div>
                    </div>
                    <div class="flex justify-end w-full mr-2">
                      <span class="underline hover:cursor-pointer" (click)="removeCustomField($index)">Remove Field</span>
                    </div>
                  </div>
                }
                <div class="flex items-end justify-center brandEventDay">
                  <p-button label="Add" styleClass="p-button-success" (onClick)="addCustomField()" icon="pi pi-plus" iconPos="top"></p-button>
                </div>
              </div>
            </div>
          </div>
          <!-- button container-->
          <div class="button-container">
            <p-button class="justify-self-start" icon="pi pi-step-backward" label="PREVIOUS STEP" (onClick)="this.activeStepIndex = this.activeStepIndex - 1"></p-button>
            <p-button class="justify-self-center" label="SAVE CHANGES" (onClick)="saveTemplate()"></p-button>
            <p-button class="justify-self-end" icon="pi pi-step-forward" iconPos="right" label="NEXT STEP" (onClick)="this.activeStepIndex = this.activeStepIndex + 1"></p-button>
          </div>
        }
        <!-- Customization -->
        @case (3) {
          <div class="flex-auto overflow-auto customization-section-container">
            <!-- Left Column - Menu (30%) -->
            <div class="customization-section-menu-column">
              <div class="customization-section-header">Customization Options</div>
              <ul class="customization-menu">
                <li class="customization-menu-item" [class.active]="activeCustomizationOption === 'gamePill'" (click)="setActiveCustomizationOption('gamePill')">Program Pill</li>
                <li class="customization-menu-item" [class.active]="activeCustomizationOption === 'videoDirectorTable'" (click)="setActiveCustomizationOption('videoDirectorTable')">Video Director View</li>
                <li class="customization-menu-item" [class.active]="activeCustomizationOption === 'programDayTable'" (click)="setActiveCustomizationOption('programDayTable')">Program Day View</li>
              </ul>
            </div>
            <!-- Right Column - Content (70%) -->
            <div class="customization-section-content-column">
              <div class="customization-section-header">Customization Options for {{ activeCustomizationOption === 'gamePill' ? 'Program Pill' : activeCustomizationOption === 'videoDirectorTable' ? 'Video Director View' : 'Program Day View' }}</div>
              <div class="flex flex-row w-full gap-10">
                @switch (activeCustomizationOption) {
                  @case ('gamePill') {
                    <div class="grid grid-cols-[2fr_minmax(150px,_1fr)] gap-2 p-2 items-center">
                      <span>Select a title field for the pill</span>
                      <p-dropdown styleClass="w-full" [options]="customizationFieldOptions" optionValue="fieldPath" optionLabel="fieldName" (onChange)="setSampleTitleValue()" [(ngModel)]="this.brandTemplate.customization.gamePill.title.fieldPath"></p-dropdown>

                      <span>Select the first line on the pill (or leave blank)</span>
                      <p-dropdown styleClass="w-full" [options]="customizationFieldOptions" [showClear]="true" emptyMessage="---" optionLabel="fieldName" (onChange)="updateCustomField($event, this.brandTemplate.customization.gamePill.line1)" [(ngModel)]="this.brandTemplate.customization.gamePill.line1"></p-dropdown>

                      <span>Select the second line on the pill (or leave blank)</span>
                      <p-dropdown styleClass="w-full" [options]="customizationFieldOptions" [showClear]="true" emptyMessage="---" optionLabel="fieldName" [(ngModel)]="this.brandTemplate.customization.gamePill.line2"></p-dropdown>

                      <span>Select the third line on the pill (or leave blank)</span>
                      <p-dropdown styleClass="w-full" [options]="customizationFieldOptions" [showClear]="true" emptyMessage="---" optionLabel="fieldName" [(ngModel)]="this.brandTemplate.customization.gamePill.line3"></p-dropdown>

                      <span>Select the field for pill status time (or leave blank)</span>
                      <p-dropdown styleClass="w-full" [options]="customizationFieldOptions" [showClear]="true" emptyMessage="---" optionLabel="fieldName" [(ngModel)]="this.brandTemplate.customization.gamePill.statusLine"></p-dropdown>
                    </div>
                    <div class="flex flex-col items-center justify-center flex-auto">
                      <span>Preview:</span>
                      <template-sample-pill [eventDate]="todaysDate" [program]="this.sampleProgram" [customization]="this.brandTemplate.customization"></template-sample-pill>
                    </div>
                  }
                  @case ('videoDirectorTable') {
                    <div class="flex-col w-full h-full columnContainer">
                      Add and remove columns for the Metadata table below.
                      <div class="grid grid-cols-[minmax(150px,_500px)_1fr] gap-1 mt-2 p-2 items-center">
                        @for (col of this.brandTemplate.customization.directorView.selectedColumns; track $index) {
                          <p-dropdown styleClass="w-full" [options]="customizationFieldOptions" class="w-full" dataKey="fieldPath" optionLabel="fieldName" [(ngModel)]="this.brandTemplate.customization.directorView.selectedColumns[$index]"></p-dropdown>
                          <p-button (onClick)="this.brandTemplate.customization.directorView.selectedColumns.splice($index, 1)" icon="pi pi-trash" styleClass="p-button-danger"></p-button>
                        }
                      </div>
                      <div class="columnEntry">
                        <p-button styleClass="p-button-raised p-button-secondary" (onClick)="this.brandTemplate.customization.directorView.selectedColumns.push({ fieldPath: '' })" icon="pi pi-plus"></p-button>
                      </div>
                      <div class="mt-4 mb-2">Specify title in header row of Program Checklists table below.</div>
                      <p-dropdown styleClass="w-[150px]" [options]="customizationFieldOptions" optionLabel="fieldName" [(ngModel)]="this.brandTemplate.customization.directorView.checklistTitle"></p-dropdown>
                    </div>
                  }
                  @case ('programDayTable') {
                    <div class="flex-col w-full h-full columnContainer">
                      Add and remove columns for this metadata table below.
                      <div class="grid grid-cols-[minmax(150px,_500px)_1fr] gap-1 mt-2 p-2 items-center">
                        @for (col of this.brandTemplate.customization.programDayView.selectedColumns; track $index) {
                          <p-dropdown styleClass="w-full" [options]="customizationFieldOptions" class="w-full" dataKey="fieldPath" optionLabel="fieldName" [(ngModel)]="this.brandTemplate.customization.programDayView.selectedColumns[$index]"></p-dropdown>
                          <p-button (onClick)="this.brandTemplate.customization.programDayView.selectedColumns.splice($index, 1)" icon="pi pi-trash" styleClass="p-button-danger"></p-button>
                        }
                      </div>
                      <div class="columnEntry">
                        <p-button styleClass="p-button-raised p-button-secondary" (onClick)="this.brandTemplate.customization.programDayView.selectedColumns.push({ fieldPath: '' })" icon="pi pi-plus"></p-button>
                      </div>
                    </div>
                  }
                }
              </div>
            </div>
          </div>
          <!-- button container-->
          <div class="button-container">
            <p-button class="justify-self-start" icon="pi pi-step-backward" label="PREVIOUS STEP" (onClick)="this.activeStepIndex = this.activeStepIndex - 1"></p-button>
            <p-button class="justify-self-center" label="SAVE CHANGES" (onClick)="saveTemplate()"></p-button>
            <p-button class="justify-self-end" icon="pi pi-step-forward" iconPos="right" label="NEXT STEP" (onClick)="this.activeStepIndex = this.activeStepIndex + 1"></p-button>
          </div>
        }
        <!-- Stages and Tasks -->
        @case (4) {
          <div class="flex flex-auto w-full h-full bg-glass-800">
            <!-- Stages & Steps-->
            <div class="flex flex-col w-[30%] p-2">
              <div class="flex w-full p-2 mb-2 bg-glass-700">Stages for "{{ this.brandTemplate.title || '' }}"</div>
              <ul [dragula]="this.DragGroup.STAGES" [(dragulaModel)]="this.brandTemplate.stages" class="flex flex-col overflow-y-auto text-xl grow basis-0">
                @for (stage of this.brandTemplate.stages; track stage; let i = $index) {
                  <li class="flex flex-row items-center w-full">
                    <span [ngClass]="{ invisible: this.brandTemplate.stages.length === 1 }" class="handle material-symbols-rounded hover:cursor-move">drag_indicator</span>
                    <div class="stageRow" (click)="this.selectStage(stage)" [ngClass]="this.selectedStage === stage ? 'selectedStage' : ''">
                      <div class="flex items-center w-full">
                        <input pInputText (change)="this.defile()" id="nameInput" class="flex-auto min-w-0" type="text" [(ngModel)]="stage.name" [readOnly]="this.selectedStage !== stage" />
                        <i class="flex-none mx-2 pi pi-trash" (click)="this.deleteStage($event, i)"></i>
                      </div>
                      <div class="m-1">
                        <p-checkbox styleClass="text-sm" label="Enable in LiveView Report" [binary]="true" (onChange)="checked($event)" [(ngModel)]="stage.reportable"></p-checkbox>
                      </div>
                    </div>
                    <span class="stageRowMarker"></span>
                  </li>
                }
              </ul>
              <div class="mt-2">
                <p-button label="Add New Stage" styleClass="p-btn-success" icon="pi pi-plus" (onClick)="this.addStage()"></p-button>
              </div>
            </div>
            <div class="flex flex-row w-[70%] p-2 border-2 border-gold rounded">
              <div class="flex flex-col w-full h-full" #stageDetails>
                <div class="flex w-full p-2 mb-2 bg-glass-700">Tasks for "{{ this.selectedStage?.name || '' }}"</div>
                <div class="flex flex-row flex-wrap col-12">
                  <div class="flex col-1">Order</div>
                  <div class="flex col-3">Team</div>
                  <div class="flex col-8">Instruction</div>
                </div>
                @if (this.selectedStage) {
                  <div [dragula]="this.DragGroup.TASKS" [(dragulaModel)]="this.selectedStage.steps" class="flex flex-col w-full h-full overflow-y-auto grow basis-0">
                    @for (step of this.selectedStage.steps; track step; let i = $index) {
                      <div class="taskRow">
                        <div class="flex items-center w-full">
                          <div class="flex items-center col-1">
                            <span [ngClass]="{ invisible: this.selectedStage.steps.length === 1 }" class="mr-1 handle material-symbols-rounded hover:cursor-move">drag_indicator</span>
                            {{ i + 1 }}
                          </div>
                          <div class="col-3">
                            <p-dropdown appendTo="body" optionValue="abbr" styleClass="w-full" optionLabel="name" [options]="this.availableTeams" [(ngModel)]="step.team"></p-dropdown>
                          </div>
                          <div class="flex flex-row col-8 flex-nowrap">
                            <input type="text" pInputText class="flex-auto min-w-0" (change)="this.defile()" [(ngModel)]="step.instruction" />
                            <p-button class="ml-3" icon="pi pi-times" (onClick)="this.deleteStep($event, i)"></p-button>
                          </div>
                        </div>
                        <div class="flex w-full">
                          <div class="flex col-1">&nbsp;</div>
                          <div class="flex items-center gap-1 col-11">
                            <p-checkbox label="Override LiveView Label" styleClass="mr-1" [binary]="true" [(ngModel)]="step.reportable"></p-checkbox>
                            @if (step.reportable) {
                              <div class="flex items-center flex-auto ml-3">
                                <input id="liveview-override" class="flex-auto w-full" type="text" pInputText [(ngModel)]="step.reportableLabel" placeholder="override instruction text (will display in all caps)" />
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                }
                <div class="mt-2">
                  <p-button label="Add New Task" styleClass="p-btn-success" icon="pi pi-plus" (onClick)="this.addStep()"></p-button>
                </div>
              </div>
            </div>
          </div>
          <!-- button container-->
          <div class="button-container">
            <p-button class="justify-self-start" icon="pi pi-step-backward" label="PREVIOUS STEP" (onClick)="this.activeStepIndex = this.activeStepIndex - 1"></p-button>
            <p-button class="justify-self-center" label="SAVE CHANGES" (onClick)="saveTemplate()"></p-button>
            <p-button class="justify-self-end" icon="pi pi-step-forward" iconPos="right" label="NEXT STEP" (onClick)="this.activeStepIndex = this.activeStepIndex + 1"></p-button>
          </div>
        }
        <!-- Teams/Services Metrics -->
        @case (5) {
          <div class="flex w-full h-full bg-glass-800 gap-x-4">
            <!-- Teams/Services -->
            <div class="flex flex-col w-[50%] p-2">
              <div class="flex-none w-full p-2 mb-2 font-bold bg-glass-800">Teams/Services GYR Checkpoint Items for "{{ this.brandTemplate.title || '' }}"</div>
              <ul class="flex-auto overflow-y-auto">
                @for (team of this.brandTemplate.checkpoint.teams; track trackByIndexFn(i); let i = $index; let length = $count) {
                  <li class="flex items-center w-full">
                    <div class="teamRow">
                      <input pInputText (change)="this.defile()" class="flex-auto min-w-0" type="text" [(ngModel)]="this.brandTemplate.checkpoint.teams[i]" />
                      <i class="flex-none mx-2 pi pi-trash" (click)="this.deleteTeam($event, i)"></i>
                    </div>
                  </li>
                }
              </ul>
              <div class="flex-none mt-2">
                <p-button label="Add New Team/Service" styleClass="p-btn-success" icon="pi pi-plus" (onClick)="this.brandTemplate.checkpoint.teams.push('')"></p-button>
              </div>
            </div>
            <div class="flex flex-col w-[50%] p-2">
              <div class="flex-none w-full p-2 mb-2 font-bold bg-glass-800">Reportable Metrics (KPIs) for "{{ this.brandTemplate.title || '' }}"</div>
              <ul class="flex-auto overflow-y-auto">
                @for (metric of this.brandTemplate.checkpoint.metrics; track trackByIndexFn(i); let i = $index; let length = $count) {
                  <li class="flex items-center w-full">
                    <div class="metricRow">
                      <input pInputText (change)="this.defile()" class="flex-auto min-w-0" type="text" [(ngModel)]="this.brandTemplate.checkpoint.metrics[i]" />
                      <i class="flex-none mx-2 pi pi-trash" (click)="this.deleteMetric($event, i)"></i>
                    </div>
                  </li>
                }
              </ul>
              <div class="flex-none mt-2">
                <p-button label="Add New Metrics" styleClass="p-btn-success" icon="pi pi-plus" (onClick)="this.brandTemplate.checkpoint.metrics.push('')"></p-button>
              </div>
            </div>
          </div>
          <!-- button container-->
          <div class="button-container">
            <p-button class="justify-self-start" icon="pi pi-step-backward" label="PREVIOUS STEP" (onClick)="this.activeStepIndex = this.activeStepIndex - 1"></p-button>
            <p-button class="justify-self-center" label="SAVE CHANGES" (onClick)="saveTemplate()"></p-button>
            <span></span>
          </div>
        }
      }
    </div>
  }
} @else {
  <p class="text-center"><em>Select a brand, then choose a template to edit or add a new template.</em></p>
}
