import { Component, DestroyRef, ElementRef, EventEmitter, inject, Input, OnDestroy, Output, Renderer2, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ConfigMap } from '@models/ClientConfig';
import { PlayerLogMessage, PlayerLogType } from '@models/PlayerLogging';
import { PlayerOptions } from '@models/PlayerOptions';
import { ConfigService } from '@services/config.service';
import { AdConfig, AuthTokenType, EngineConfig, IPlayer, ListenableEventBase, ListenableEventCallback, ListenableEvents, MediaJSONData, MediaStateChangeEvent, PlaybackConfig, PlayConfig, Player, PlayerConfigEnvType, SCTEEvent, SegmentPlaybackEvent, SetupConfig, UIConfig, UIControlName } from '@top/player-web-lite';
import { PlayerUIModule } from '@top/player-web-lite/dist/modules/player-ui/player-ui-lite';
import { MessageService } from 'primeng/api';
import { first } from 'rxjs';
import { environment } from '../../../../environments/environment';

type PlayerEventListener = ListenableEventCallback<ListenableEventBase>;

@Component({
  selector: 'glass-player',
  templateUrl: './glass-player.component.html',
  styleUrls: ['./glass-player.component.css'],
  providers: [MessageService],
})
export class GlassPlayer implements OnDestroy {
  @Output() playerMessage = new EventEmitter<PlayerLogMessage>();
  @Output() mediaLoaded = new EventEmitter<any[]>();
  @Input() mediaProfiles = [];
  @Input() noMargin = false;
  @Input() muted = false;
  @Input() volume = 50;
  @ViewChild('parentContainer') parentContainer!: ElementRef;
  config!: ConfigMap;
  currentOpts: PlayerOptions;
  containerId = '';
  player?: IPlayer;
  manualBitrate = false;
  loggingListeners: [ListenableEvents, PlayerEventListener][] = [];
  playerVersion = '';
  selectedMediaProfile = '';
  private destroyRef = inject(DestroyRef);
  constructor(
    private readonly cs: ConfigService,
    private readonly renderer: Renderer2,
  ) {
    cs.CurrentConfig$.pipe(
      takeUntilDestroyed(this.destroyRef),
      first((value) => value != null && value.config != null && value.config.key != null),
    ).subscribe((n) => (this.config = n.config));
    this.currentOpts = {
      mediaId: '',
      companyId: '',
      assetId: '',
      adProfile: '',
      env: '',
      cappedBitrate: true,
      logging: false,
    };
    this.containerId = this.generateContainerId();
  }

  generateContainerId() {
    return `pc_${Date.now()}`;
  }

  async ngOnDestroy() {
    if (this.player) {
      await this.destroyPlayer();
    }
  }

  async destroyPlayer() {
    const { player } = this;
    if (player) {
      this.detachLogging();
      try {
        await player.stop();
      } catch (e) {
        this.log(`unable to stop player: ${e}`);
      }
      try {
        await player.destroy();
      } catch (e) {
        this.log(`unable to destroy player: ${e}`);
      }
    }
    this.destroyContainer();
    return true;
  }

  destroyContainer() {
    for (const element of this.parentContainer.nativeElement.querySelectorAll('.video-container')) {
      element.parentElement?.removeChild(element);
    }
  }

  async resetPlayer() {
    await this.play(this.currentOpts);
  }

  async play(opts: PlayerOptions) {
    try {
      this.currentOpts = opts;

      if (this.player) await this.destroyPlayer();

      this.createPlayerElements();

      const containerElement = document.getElementById(this.containerId) as HTMLElement;
      if (!containerElement) throw new Error('Cannot play without container element');

      const setupConfig = this.createSetupConfig();
      this.player = Player.create(containerElement, setupConfig);

      this.player.addModule(PlayerUIModule);

      this.playerVersion = this.player.version;
      this.containerId = this.generateContainerId();

      await this.player.setup();

      this.attachLogging();

      const looksLikeAUrl = this.currentOpts.mediaId?.toLowerCase().startsWith('http');

      if (looksLikeAUrl) return this.playByUrl();
      return this.playByMediaJson();
    } catch (ex) {
      this.log(`failure to play(): ${ex}`);
    }
  }

  async playByMediaJson() {
    const { adProfile, cdn, companyId, env, mediaId, token } = this.currentOpts;

    if (!mediaId) throw new Error('Cannot playByMediaJson without a media ID');
    if (!this.player) throw new Error('Cannot play without a Player instance');

    const mediaData: MediaJSONData = {
      accessToken: token?.jws,
      accessTokenType: AuthTokenType.JWS,
      appId: this.config.key,
      companyId,
      env: (env ?? 'prod') as PlayerConfigEnvType,
      mediaId,
      cdnProfile: cdn,
    };

    const adConfig: AdConfig | undefined = adProfile
      ? {
          profile: adProfile,
        }
      : undefined;
    const playConfig: PlayConfig = {
      ads: adConfig,
      features: {
        tve: {
          enabled: true,
        },
      },
    };

    this.player.playByMediaJson(mediaData, playConfig);
  }

  setMediaProfile(id: string) {
    this.log(`setMediaProfile(id: ${id})`);

    this.selectedMediaProfile = id;

    // if this is our first time setting the bitrate manually we need to restart the player
    // because otherwise capBitrateToSize will override the selected profile.
    if (this.manualBitrate === false) {
      this.manualBitrate = true;
      this.currentOpts.cappedBitrate = false;

      // now when we reset the player the profile will be loaded once we receive the MediaLoaded
      // message

      this.play(this.currentOpts);
    } else {
      this.player?.setVideoQuality(id);
    }
  }

  async playByUrl() {
    const { adProfile, mediaId } = this.currentOpts;

    if (!mediaId) throw new Error('Cannot playByUrl without a URL');
    if (!this.player) throw new Error('Cannot play without a Player instance');

    const url = mediaId;

    const adConfig: AdConfig | undefined = adProfile
      ? {
          profile: adProfile,
        }
      : undefined;
    const playConfig: PlayConfig = {
      ads: adConfig,
    };

    this.player.playByUrl(url, playConfig);
  }

  detachLogging() {
    if (!this.player) return;

    try {
      this.loggingListeners.forEach(([event, listener]) => {
        this.player?.off(event, listener);
      });
    } catch {
      // intentionally ignoring failures
    }

    this.loggingListeners.length = 0;
  }

  log(msg: string) {
    console.log(`🥃 [${this.currentOpts.mediaId}] ${msg}`);
  }

  attachLogging() {
    if (!this.player) return;

    this.loggingListeners.push(
      [
        ListenableEvents.Ready,
        () => {
          this.log(`[ready]`);
        },
      ],
      [
        ListenableEvents.MediaStateChanged,
        // @ts-expect-error Lite Player types aren't kosher
        ({ previousState, currentState }: MediaStateChangeEvent) => {
          this.log(`[mediaStateChanged] ${previousState} → ${currentState}`);
        },
      ],
      [
        ListenableEvents.SourceLoaded,
        // instead of `mediaLoaded`
        () => {
          addListenerForFirstSegment();

          if (this.manualBitrate) {
            this.player?.setVideoQuality(this.selectedMediaProfile);
          } else {
            this.mediaLoaded.emit(this.player?.getAvailableVideoQualities());
          }
        },
      ],
      [
        ListenableEvents.SCTE,
        // instead of `cueEnter`
        (scteEvent: SCTEEvent) => {
          this.log(`[SCTE] message types: ${scteEvent.messages.join(', ')}`);
          this.playerMessage.emit({
            type: PlayerLogType.Scte,
            value: scteEvent,
          });
        },
      ],
    );

    // @ts-expect-error because I can't make TS happy
    const listenerForFirstSegment: PlayerEventListener = (segmentPlaybackEvent: SegmentPlaybackEvent) => {
      const { url } = segmentPlaybackEvent;
      if (url) {
        this.playerMessage.emit({
          type: PlayerLogType.CdnDetected,
          value: url.split('/')[2],
        });
        removeListenerForFirstSegment();
      }
    };

    const addListenerForFirstSegment = () => {
      this.loggingListeners.push([ListenableEvents.SegmentPlayback, listenerForFirstSegment]);
      this.player?.on(ListenableEvents.SegmentPlayback, listenerForFirstSegment);
    };

    const removeListenerForFirstSegment = () => {
      this.player?.off(ListenableEvents.SegmentPlayback, listenerForFirstSegment);
      this.loggingListeners = this.loggingListeners.filter(([event, listener]) => {
        return !(event === ListenableEvents.SegmentPlayback && listener === listenerForFirstSegment);
      });
    };

    this.loggingListeners.forEach(([event, listener]) => {
      this.player?.on(event, listener);
    });
  }

  createPlayerElements() {
    this.containerId = this.generateContainerId();
    const vc = document.createElement('div');
    vc.id = this.containerId;
    vc.className = 'video-container';
    this.parentContainer.nativeElement.prepend(vc);
    return true;
  }

  createSetupConfig(): SetupConfig {
    const playbackConfig: PlaybackConfig = {
      // @ts-expect-error because Lite Player didn't copy Bitmovin configs?
      autoplay: true,
      muted: this.muted,
      volume: this.volume,
    };
    const engineConfig: EngineConfig = {
      key: '6f48af99-edb0-4411-8979-2c7859eddd9d',
      playback: playbackConfig,
    };
    const uiConfig: UIConfig = {
      enabled: true,
      theme: {
        accentColor: 'hsl(46deg 100% 50%)',
      },
      inactivityThreshold: 2000,
      components: {
        disable: [UIControlName.Air_Play, UIControlName.Bitrate, UIControlName.Cast, UIControlName.Fullscreen],
        controls: {
          ProgressBar: {
            displayThumbnail: false,
          },
        },
      },
    };
    const setupConfig: SetupConfig = {
      debug: environment?.topDebug ?? false,
      engine: engineConfig,
      ui: uiConfig,
    };
    return setupConfig;
  }
}
