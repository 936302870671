import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CheckboxChangeEvent } from 'primeng/checkbox';
const log = console.log.bind(null, '[custom-field]');

@Component({
  selector: 'ops-template-custom-field',
  templateUrl: './custom-field.component.html',
  styleUrls: ['./custom-field.component.css'],
  providers: [],
})
export class CustomFieldComponent {
  @Input() field?: any;
  @Input() inCollection: boolean = false;
  @Input() overrideName: string = '';
  @Output() selectionChanged: EventEmitter<any> = new EventEmitter();
  constructor() {}
  updateCheckedStatus(evt?: CheckboxChangeEvent) {
    if (!evt && this.inCollection) {
      this.selectionChanged.emit({
        checked: true,
        field: this.field,
        override: this.overrideName,
      });
    } else if (evt) {
      this.selectionChanged.emit({
        checked: evt.checked === true,
        field: this.field,
        override: this.overrideName,
      });
    }
  }
}
