import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';

import { MenuItem } from 'primeng/api';

@Component({
  selector: 'glass-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.css'],
})
export class BadgeComponent implements OnInit {
  initials = '';
  firstName = '';
  items: MenuItem[] = [
    {
      label: 'Logout',
      icon: 'pi pi-sign-out',
      command: () => {
        this.auth.tokenManager.clear();
        this.router.navigate(['/logout']);
      },
    },
  ];
  constructor(
    @Inject(OKTA_AUTH) private auth: OktaAuth,
    private router: Router,
  ) {}

  ngOnInit() {
    this.auth.getUser().then(
      (claims) => {
        this.initials = this.getInitials(claims);
        this.firstName = claims.given_name || '';
      },
      (error) => {
        console.error('Failed to get user from Okta (try logging back in?)', error);
      },
    );
  }

  getInitials(user: any): string {
    if (!user) return '';
    const firstInitial = (user.firstName || user.given_name || ' ')[0].toUpperCase();
    const lastInitial = (user.lastName || user.family_name || ' ')[0].toUpperCase();
    return `${firstInitial}${lastInitial}`.trim();
  }
}
