import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { primeng_modules } from '../primeng-imports';
import { CustomMediaComponent } from './custom-media/custom-media.component';
import { GlassPlayer } from './glass-player/glass-player.component';
import { LogoutComponent } from './logout/logout.component';
import { BadgeComponent } from './badge/badge.component';
import { WebRtcPlayer } from './webrtc-player/webrtc-player.component';
import { TemplateEditorComponent } from './template-editor/template-editor.component';
import { DragulaModule } from 'ng2-dragula';
import { ToETPipe } from '../pipes/to-et.pipe';
import { SplitCamelCasePipe } from '../pipes/split-camel-case.pipe';
import { CustomFieldComponent } from './custom-field-component/custom-field.component';
import { TemplateEditorComponentV2 } from './template-editor-v2/template-editor.component';
import { EventWatchAkamaiStatsComponent } from '../../modules/watch/components/watch/event-watch-akamai-stats/event-watch-akamai-stats.component';
import { EventWatchYospaceStatsComponent } from '../../modules/watch/components/watch/event-watch-yospace-stats/event-watch-yospace-stats.component';
import { SamplePillSignal } from './sample-pill-signal/sample-pill-signal.component';
import { SamplePill } from './sample-pill/template-sample-pill.component';
const components = [GlassPlayer, CustomMediaComponent, SamplePill, SamplePillSignal, TemplateEditorComponentV2, CustomFieldComponent, LogoutComponent, BadgeComponent, WebRtcPlayer, TemplateEditorComponent, ToETPipe, SplitCamelCasePipe];

@NgModule({
  declarations: components,
  imports: [CommonModule, DragulaModule.forRoot(), EventWatchAkamaiStatsComponent, EventWatchYospaceStatsComponent, FormsModule, ReactiveFormsModule, primeng_modules],
  exports: [...components, EventWatchAkamaiStatsComponent, EventWatchYospaceStatsComponent],
})
export class SharedComponentsModule {}
