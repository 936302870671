import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, repeat, shareReplay, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

const handleError = (error: HttpErrorResponse) => {
  if (error.status === 0) {
    // A client-side or network error occurred. Handle it accordingly.
    console.error('A client-side error occurred (CORS or network blocking):', error.error);
  } else {
    // The backend returned an unsuccessful response code.
    // The response body may contain clues as to what went wrong.
    console.error(`The server returned code ${error.status}, body was: `, error.error);
  }
  // Return an observable with a user-facing error message.
  return throwError(() => new Error('Something bad happened; please try again later.'));
};

@Injectable({
  providedIn: 'root',
})
export class VideoService {
  eventTimer: any;
  public CurrentEvents$: Observable<any>;
  public CdnOptions$: Observable<any>;

  constructor(private http: HttpClient) {
    // eslint-disable-next-line prettier/prettier
    this.CurrentEvents$ = this.http.get<any>(`${environment.glassApiUrl}/v1/currentevents`).pipe(
      repeat({ delay: 30_000 }),
      catchError(handleError),
      shareReplay({ bufferSize: 1, refCount: true, windowTime: 30_000 }),
    );
    // eslint-disable-next-line prettier/prettier
    this.CdnOptions$ = this.http.get<any>(`${environment.glassApiUrl}/v1/config/cdns`).pipe(
      repeat({ delay: 60_000 }),
      catchError(handleError),
      shareReplay({ bufferSize: 1, refCount: true, windowTime: 60_000 }),
    );
  }

  getRunningEvents() {
    return this.CurrentEvents$;
  }

  getCDNOptions() {
    return this.CdnOptions$;
  }

  getEvents() {
    return this.http.get<any>(`${environment.glassApiUrl}/v1/events`);
  }

  getEvent(eventId: number) {
    return this.http.get<any>(`${environment.glassApiUrl}/v1/events/${eventId}`);
  }

  getTokenForEvent(eventId: number) {
    return this.http.get<any>(`${environment.glassApiUrl}/v1/token/${eventId}`);
  }

  getTokenForMedium(mediaId: string, env: string) {
    return this.http.get<any>(`${environment.glassApiUrl}/v1/token/${mediaId}/${env}`);
  }

  getMediumData(mediaId: string, env: string) {
    return this.http.get<any>(`${environment.glassApiUrl}/v1//medium/${mediaId}/${env}`);
  }

  getStreamStatus(streamIds: string) {
    return this.http.get<any>(`${environment.glassApiUrl}/v1/stream/health/${streamIds}`);
  }

  getEdgeVersions() {
    return this.http.get<any>(`${environment.glassApiUrl}/v1/edge/versions`);
  }

  getScteForEvent(eventId: string) {
    return this.http.get<any>(`${environment.glassApiUrl}/v1/events/${eventId}/logs`);
  }

  getQuickVodReport(brand: string, selectedTimeFrame: string) {
    return this.http.get<any>(`${environment.glassApiUrl}/v1/quickVod/${brand}/${selectedTimeFrame}`);
  }
}
