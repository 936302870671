export enum EventSourceType {
  SLE = 'SLE',
  LWS = 'LWS',
  UNSET = '',
}

export interface MetadataRecord {
  _id: string;
  name: string;
  createdAt: Date;
  updatedAt: Date;
}
export const sampleRecord: MetadataRecord = {
  _id: '1',
  name: ' ',
  createdAt: new Date(),
  updatedAt: new Date(),
};

export interface MetadataDetail {
  _id: string;
  sourceType: MetadataRecord;
  fieldName: string;
  jsonPaths: string[];
  transforms: string;
  enabled: boolean;
}

export interface MetadataField {
  metaData: MetadataDetail;
  overrideName: string;
}
