import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { EventSourceType, MetadataDetail } from '@models/ops.v2/Metadata';
import { OpsBrandEventTeam, OpsEventDayProgram, OpsEventDayProgramStatus } from '@models/ops.v2/OpsEvents';
import { CustomizedField, OpsBrandTemplate, OpsBrandTemplateStage, OpsBrandTemplateStep } from '@models/ops.v2/OpsTemplate';
import { OpsService } from '@services/ops/ops.service.v2';
import { DragulaService } from 'ng2-dragula';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CheckboxChangeEvent } from 'primeng/checkbox';
import { Subscription } from 'rxjs';
const log = console.log.bind(null, '[template-editor]');

@Component({
  selector: 'ops-template-editor-v2',
  templateUrl: './template-editor.component.html',
  styleUrls: ['./template-editor.component.scss'],
  providers: [MessageService, ConfirmationService],
})
export class TemplateEditorComponentV2 implements OnInit, OnChanges, OnDestroy {
  @ViewChild('stageDetails') stageDetails: any;
  @Input() brandId?: string;
  @Input() templateId?: string = '';
  @Output() templateSavedEvent = new EventEmitter<OpsBrandTemplate>();
  @Output() templateDeletedEvent = new EventEmitter<string>();
  clean = true;
  sourceChoice = EventSourceType;
  activeIndex = 0;
  sampleLine: CustomizedField = {
    fieldPath: '',
    fieldName: '',
    display: '',
    template: '',
  };
  sampleProgram: OpsEventDayProgram = {
    _id: '',
    id: '',
    eventId: '',
    title: '',
    brand: '',
    checked: false,
    metadata: {},
    customMetadata: {},
    ack: {},
    status: OpsEventDayProgramStatus.INACTIVE,
    checklist: {
      stages: [],
    },
    customization: {
      gamePill: {
        title: this.sampleLine,
        line1: this.sampleLine,
        line2: this.sampleLine,
        line3: this.sampleLine,
        statusLine: this.sampleLine,
      },
      programDayView: {
        selectedColumns: [],
      },
      directorView: {
        selectedColumns: [],
        checklistTitle: this.sampleLine,
      },
    },
  };
  activeStepIndex = 0;
  brandTeams: OpsBrandEventTeam[] = [];
  brandTemplate?: OpsBrandTemplate;
  selectedStage?: OpsBrandTemplateStage;
  lastDraggedIndex = -1;
  fields: any[] = [];
  selectedFields: string[] = [];
  availableTeams: any[] = [];
  selectedTeams: string[] = [];
  todaysDate: string = Date.now().toString();
  customOptionsList: any[] = [
    { value: 'string', label: 'Text' },
    { value: 'time', label: 'Time (e.g. "12:45")' },
    { value: 'timedot', label: 'Time with Dot' },
    { value: 'date', label: 'Date (mm/dd/yyyy)' },
  ];

  steps = [
    {
      label: 'Template Title & Options',
    },
    {
      label: 'Teams',
    },
    {
      label: 'Metadata',
    },
    {
      label: 'Customization',
    },
    {
      label: 'Stages & Tasks',
    },
    {
      label: 'Services & Metrics',
    },
  ];
  DragGroup = {
    STAGES: 'STAGES',
    TASKS: 'TASKS',
  };
  subs = new Subscription();
  activeCustomizationOption: 'gamePill' | 'videoDirectorTable' | 'programDayTable' = 'gamePill';

  constructor(
    private readonly opsService: OpsService,
    private readonly confirmationService: ConfirmationService,
    private readonly messageService: MessageService,
    private readonly dragulaService: DragulaService,
  ) {
    const dragGroups = Object.values(this.DragGroup);

    // enable drag'n'drop "handles"
    dragGroups.forEach((group: string) => {
      this.dragulaService.destroy(group);
      this.dragulaService.createGroup(group, {
        moves: (el, container, handle) => {
          return handle?.classList.contains('handle') ?? false;
        },
      });
    });

    // flag any drag'n'drop as dirty
    dragGroups.forEach((group: string) => {
      this.subs.add(
        this.dragulaService.drop(group).subscribe(() => {
          this.defile();
        }),
      );
    });
  }

  ngOnInit(): void {
    this.updateView();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  setSampleTitleValue(): string {
    let sampleVal = 'Sample Value';
    if (this.brandTemplate && !!this.brandTemplate.customization.gamePill.title) {
      switch (this.brandTemplate.customization.gamePill.title.display) {
        case 'time':
        case 'timedot': {
          sampleVal = '12:45';
          break;
        }
        case 'date': {
          sampleVal = '2025-08-12';
          break;
        }
      }
    }
    return sampleVal;
  }

  ngOnChanges(changes: SimpleChanges): void {
    log('changes', changes);
    this.clean = true;
    this.updateView();
  }

  setupTeamsMenu() {
    this.availableTeams = [];
    this.availableTeams.push({ abbr: 'EC', name: 'Event Coordinator' }, { abbr: 'BEC', name: 'Backup Event Coordinator' });
    this.availableTeams.push(...(this.brandTemplate?.teams || []));
  }

  isSelected(id: string): boolean {
    return (
      this.brandTemplate?.selectedMetadata.some((g) => {
        return g.metaData?._id == id;
      }) ?? true
    );
  }

  addCustomField(): void {
    if (this.brandTemplate) {
      this.brandTemplate.customMetadata = this.brandTemplate.customMetadata || {
        fields: [],
      };
      this.brandTemplate.customMetadata.fields.push({ key: 'New Field', value: '', display: '' });
    }
  }
  removeCustomField(idx: number) {
    this.brandTemplate?.customMetadata.fields.splice(idx, 1);
  }
  getOverrideName(id: string): string {
    if (this.brandTemplate) {
      const idx = this.brandTemplate.selectedMetadata.findIndex((n) => n.metaData?._id == id);
      return idx == -1 ? '' : this.brandTemplate.selectedMetadata[idx].overrideName;
    }
    return '';
  }

  updateFieldStatus(val: { field: MetadataDetail; checked: boolean; override: string }) {
    if (this.brandTemplate) {
      const idx = this.brandTemplate.selectedMetadata.findIndex((n) => n.metaData._id == val.field._id);
      if (val.checked === true) {
        if (idx == -1) this.brandTemplate.selectedMetadata.push({ metaData: val.field, overrideName: val.override });
        else this.brandTemplate.selectedMetadata[idx].overrideName = val.override;
      } else {
        if (idx > -1) this.brandTemplate.selectedMetadata.splice(idx, 1);
      }
    }
  }

  onActiveIndexChange(index: number) {
    if (index !== this.activeStepIndex) {
      if (this.activeStepIndex === 0 && this.brandTemplate?.title.trim() === '') {
        this.messageService.add({ severity: 'info', summary: 'Title required', detail: 'Cannot proceed without a template title' });
        return;
      }
      this.activeStepIndex = index;
    }
  }
  updateView() {
    if (this.brandId) {
      this.opsService.getTeamsForBrand(this.brandId).subscribe((n) => {
        this.brandTeams = n;
      });
      this.brandTemplate = this.createEmptyBrandTemplate(this.brandId);
      this.setupTeamsMenu();
      this.selectedStage = this.brandTemplate.stages[0];
      this.activeStepIndex = 0;
      this.opsService.getMetadataForEventType('SLE').subscribe((p: any) => {
        const groupedFields = p.reduce(
          (acc: any, cv: any) => {
            // Group initialization
            if (!acc[cv.sourceType.name]) {
              acc[cv.sourceType.name] = [];
            }

            // Grouping
            // FIX: only pushing the object that contains id and value
            acc[cv.sourceType.name].push({ _id: cv._id, fieldName: cv.fieldName });

            return acc;
          },
          <any>{},
        );
        this.fields = Object.keys(groupedFields).map((p: any) => {
          return {
            name: p,
            fields: groupedFields[p],
          };
        });
      });
      if (this.templateId && this.templateId != 'new') {
        this.opsService.getBrandTemplate(this.brandId, this.templateId).subscribe((b) => {
          if (Object.keys(b).length === 0) {
            // the service returns an empty object if you make a bad query, or your template was killed
            this.messageService.add({ severity: 'error', summary: 'No template found!', detail: 'Either a technical glitch or someone deleted your template.' });
            return;
          }
          this.brandTemplate = b;
          this.selectedTeams = (this.brandTemplate?.teams || []).map((n) => n._id);
          this.setupTeamsMenu();
          this.brandTemplate.active = this.brandTemplate.active !== false;
          this.selectedStage = this.brandTemplate.stages?.[0] ?? this.createEmptyStage();
        });
      } else {
        log('no brand, no template');
      }
    }
  }

  selectStage(stage: OpsBrandTemplateStage) {
    if (this.selectedStage === stage) return;
    this.selectedStage = stage;
    const { classList } = this.stageDetails.nativeElement;
    if (classList.contains('animateStageDetails')) {
      classList.replace('animateStageDetails', 'animateStageDetailsAgain');
    } else if (classList.contains('animateStageDetailsAgain')) {
      classList.replace('animateStageDetailsAgain', 'animateStageDetails');
    } else {
      classList.add('animateStageDetails');
    }
  }

  addStage() {
    this.brandTemplate?.stages.push(this.createEmptyStage());
  }

  saveTemplate() {
    if (!this.brandTemplate) return;
    const template = { ...this.brandTemplate };
    template.stages.forEach((p) => this.reorderSteps(p));
    this.opsService.saveBrandTemplate(template).subscribe(({ success, message }) => {
      if (success) {
        this.messageService.add({ severity: 'info', summary: 'Saved', detail: 'Template Saved' });
        this.clean = true;
        this.templateId = '';
        this.updateView();
        this.templateSavedEvent.emit(template);
      } else {
        this.messageService.add({ severity: 'error', summary: 'Save Failed', detail: message });
      }
    });
  }
  deleteTemplate({ brand: brandId, id: templateId }: OpsBrandTemplate) {
    this.opsService.deleteBrandTemplate(brandId, templateId).subscribe(({ success, message }) => {
      if (success) {
        this.messageService.add({ severity: 'info', summary: 'Deleted', detail: message });
        this.templateDeletedEvent.emit(templateId);
        this.clean = true;
      } else {
        this.messageService.add({ severity: 'error', summary: 'Deletion Failed', detail: message });
      }
    });
  }

  deleteStage(event: Event, idx: number) {
    if (!this.brandTemplate) return;
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: `Delete "${this.brandTemplate.stages[idx].name}" and all its tasks?`,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        if (!this.brandTemplate) return;
        this.clean = false;
        this.brandTemplate.stages.splice(idx, 1);
        if (this.brandTemplate.stages.length === 0) {
          this.addStage();
        }
        this.selectStage(this.brandTemplate.stages[0]);
      },
      reject: () => {},
    });
  }

  deleteTeam(event: Event, idx: number) {
    if (!this.brandTemplate) return;
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: `Delete Team "${this.brandTemplate.checkpoint.teams[idx]}" from checkpoint?`,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        if (!this.brandTemplate) return;
        this.brandTemplate.checkpoint.teams.splice(idx, 1);
        this.clean = false;
      },
      reject: () => {},
    });
  }

  deleteMetric(event: Event, idx: number) {
    if (!this.brandTemplate) return;
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: `Delete Metric "${this.brandTemplate.checkpoint.metrics[idx]}" from checkpoint?`,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        if (!this.brandTemplate) return;
        this.brandTemplate.checkpoint.metrics.splice(idx, 1);
        this.clean = false;
      },
      reject: () => {},
    });
  }

  deleteStep(event: Event, idx: number) {
    if (!this.selectedStage) return;
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: `Delete Task "${this.selectedStage.steps[idx].instruction}"?`,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        if (!this.selectedStage) return;
        this.selectedStage.steps.splice(idx, 1);
        this.clean = false;
      },
      reject: () => {},
    });
  }

  createEmptyStep(ordinal = 0): OpsBrandTemplateStep {
    return {
      id: '',
      ordinal,
      team: '',
      instruction: '',
    };
  }
  createEmptyStage(): OpsBrandTemplateStage {
    return {
      id: '',
      ordinal: 0,
      name: 'New Stage',
      steps: [this.createEmptyStep()],
    };
  }
  createEmptyBrandTemplate(brand: string): OpsBrandTemplate {
    return {
      id: 'new',
      brand,
      checkpoint: { teams: [], metrics: [] },
      title: '',
      stages: [this.createEmptyStage()],
      active: true,
      selectedMetadata: [],
      customMetadata: { fields: [] },
      customization: {
        directorView: { selectedColumns: [], checklistTitle: this.sampleLine },
        programDayView: { selectedColumns: [] },
        gamePill: { title: this.sampleLine, line1: this.sampleLine, line2: this.sampleLine, line3: this.sampleLine, statusLine: this.sampleLine },
      },
      sourceType: EventSourceType.UNSET,
      teams: [],
    };
  }

  get customizationFieldOptions(): any[] {
    const opts: any[] = [];
    try {
      this.brandTemplate?.selectedMetadata.forEach((n) => {
        opts.push({
          fieldPath: 'selectedMetadata.' + n.metaData.fieldName,
          fieldName: n.overrideName ? n.overrideName : n.metaData.fieldName,
          display: 'string',
        });
      });
      this.brandTemplate?.customMetadata.fields.forEach((m) => {
        opts.push({
          fieldPath: 'customMetadata.' + m.key,
          fieldName: m.key,
          display: m.display,
        });
      });
    } catch (ex) {
      console.debug(ex);
    }
    return opts;
  }

  // selected teams changed, rebuild team choice list.
  onChecked(event: any) {
    this.setupTeamsMenu();
  }

  addStep() {
    this.clean = false;
    this.selectedStage?.steps.push(this.createEmptyStep(this.selectedStage.steps.length));
  }

  trackByIndexFn(index: any) {
    return index;
  }

  reorderSteps(stage?: OpsBrandTemplateStage) {
    if (!stage) return;
    stage.steps.forEach((n, idx) => {
      n.ordinal = idx + 1;
    });
  }

  defile() {
    this.clean = false;
  }

  checked(event: CheckboxChangeEvent) {
    // avoid bubbling up to trigger a row select change on chceck.
    event.originalEvent?.stopPropagation();
  }

  setActiveCustomizationOption(option: 'gamePill' | 'videoDirectorTable' | 'programDayTable'): void {
    this.activeCustomizationOption = option;
  }

  updateCustomField(e: any, arg0: CustomizedField) {}
}
