import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OpsEventDayProgram } from '@models/ops.v2/OpsEvents';
import { CustomizedField, TemplateCustomization } from '@models/ops.v2/OpsTemplate';
import { OperateGameSignalTickService } from '@modules/ops_v2/services/operate-game-signal-tick/operate-game-signal-tick.service';

@Component({
  selector: 'template-sample-pill',
  templateUrl: './template-sample-pill.component.html',
  styleUrls: ['./template-sample-pill.component.css'],
})
export class SamplePill implements OnInit {
  @Input() eventDate: string = '';
  @Input() isUserCoordinator = false;
  @Input() customization!: TemplateCustomization;
  @Input() program!: OpsEventDayProgram;
  @Output() gameSelected = new EventEmitter<number>();
  @Output() toggleMetadata = new EventEmitter<void>();
  public selectedIndex = 0;

  constructor(private readonly tickService: OperateGameSignalTickService) {}

  ngOnInit() {
    this.gameSelected.emit(this.selectedIndex);
  }

  sampleValue(field: CustomizedField): string {
    switch (field.display) {
      case 'time':
      case 'timedot': {
        return '__:__ ET';
      }
      case 'date': {
        return '__/__/__';
      }
      default:
        return 'Value';
    }
  }

  ngOnDestroy() {}
}
