/**
 *  ENUM of message types to bubble up from the player
 */
export enum PlayerLogType {
  CdnDetected = 1,
  Scte,
}

export interface PlayerLogMessage {
  type: PlayerLogType;
  value: any;
}
