import { Observable } from 'rxjs';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { OktaAuth } from '@okta/okta-auth-js';
import { OKTA_AUTH } from '@okta/okta-angular';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  intercepted_methods = ['OPTIONS', 'GET', 'PUT', 'POST', 'DELETE'];
  constructor(@Inject(OKTA_AUTH) public oktaAuth: OktaAuth) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.oktaAuth.getIdToken() || '';

    if (this.intercepted_methods.includes(request.method) && request.url.toLowerCase().startsWith(environment.glassApiUrl)) {
      request = request.clone({
        setHeaders: {
          'wm-app': 'plato-glass-ui',
          Authorization: `Bearer ${token}`,
        },
      });
    }
    return next.handle(request);
  }
}
