import { SharedModule } from 'primeng/api';
import { AccordionModule } from 'primeng/accordion';
import { AvatarModule } from 'primeng/avatar';
import { BadgeModule } from 'primeng/badge';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipsModule } from 'primeng/chips';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { FieldsetModule } from 'primeng/fieldset';
import { FocusTrapModule } from 'primeng/focustrap';
import { InplaceModule } from 'primeng/inplace';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { InputMaskModule } from 'primeng/inputmask';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ListboxModule } from 'primeng/listbox';
import { MenubarModule } from 'primeng/menubar';
import { MeterGroupModule } from 'primeng/metergroup';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { MultiSelectModule } from 'primeng/multiselect';
import { PanelModule } from 'primeng/panel';
import { ProgressBarModule } from 'primeng/progressbar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SidebarModule } from 'primeng/sidebar';
import { StepsModule } from 'primeng/steps';
import { TableModule } from 'primeng/table';
import { TabMenuModule } from 'primeng/tabmenu';
import { TabViewModule } from 'primeng/tabview';
import { TagModule } from 'primeng/tag';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { ToastModule } from 'primeng/toast';
import { TreeModule } from 'primeng/tree';
import { TreeTableModule } from 'primeng/treetable';
export const primeng_modules = [SharedModule, AccordionModule, AvatarModule, BadgeModule, ButtonModule, CalendarModule, CardModule, CheckboxModule, ChipsModule, ConfirmDialogModule, ConfirmPopupModule, DialogModule, DividerModule, DropdownModule, FieldsetModule, FocusTrapModule, InplaceModule, InputGroupModule, InputGroupAddonModule, InputMaskModule, InputSwitchModule, InputTextModule, InputTextareaModule, ListboxModule, MenubarModule, MeterGroupModule, MessagesModule, MessageModule, MultiSelectModule, PanelModule, ProgressBarModule, RadioButtonModule, SelectButtonModule, SidebarModule, StepsModule, TableModule, TabMenuModule, TabViewModule, TagModule, TieredMenuModule, ToastModule, TreeModule, TreeTableModule];
