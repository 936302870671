import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { OpsBrandTemplate, OpsBrandTemplateStage, OpsBrandTemplateStep } from '@models/ops.v1/OpsTemplate';
import { OpsService } from '@services/ops/ops.service.v1';
import { DragulaService } from 'ng2-dragula';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CheckboxChangeEvent } from 'primeng/checkbox';
import { Subscription } from 'rxjs';


const log = console.log.bind(null, '[template-editor]');

@Component({
  selector: 'ops-template-editor',
  templateUrl: './template-editor.component.html',
  styleUrls: ['./template-editor.component.css'],
  providers: [MessageService, ConfirmationService],
})
export class TemplateEditorComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('stageDetails') stageDetails: any;
  @Input() brandId?: string;
  @Input() templateId?: string = '';
  @Output() templateSavedEvent = new EventEmitter<OpsBrandTemplate>();
  @Output() templateDeletedEvent = new EventEmitter<string>();
  clean = true;
  activeIndex = 0;
  activeStepIndex = 0;
  brandTemplate?: OpsBrandTemplate;
  selectedStage?: OpsBrandTemplateStage;
  lastDraggedIndex = -1;
  steps = [
    {
      label: 'Template Title & Options',
    },
    {
      label: 'Stages & Tasks',
    },
    {
      label: 'Teams/Services & Metrics',
    },
  ];
  DragGroup = {
    STAGES: 'STAGES',
    TASKS: 'TASKS',
  };
  subs = new Subscription();
  brandTeamOptions = [
    {
      teamCode: 'EC',
      teamName: 'Event Coordinators',
    },
    {
      teamCode: 'BEC',
      teamName: 'Backup Event Coordinators',
    },
    {
      teamCode: 'LDO',
      teamName: 'Live DevOps',
    },
    {
      teamCode: 'LOT',
      teamName: 'Live Operations Team',
    },
    {
      teamCode: 'VT',
      teamName: 'Verification Team',
    },
  ];
  constructor(
    private readonly opsService: OpsService,
    private readonly confirmationService: ConfirmationService,
    private readonly messageService: MessageService,
    private readonly dragulaService: DragulaService,
  ) {
    const dragGroups = Object.values(this.DragGroup);

    // enable drag'n'drop "handles"
    dragGroups.forEach((group: string) => {
      this.dragulaService.destroy(group);
      this.dragulaService.createGroup(group, {
        moves: (el, container, handle) => {
          return handle?.classList.contains('handle') ?? false;
        },
      });
    });

    // flag any drag'n'drop as dirty
    dragGroups.forEach((group: string) => {
      this.subs.add(
        this.dragulaService.drop(group).subscribe(() => {
          this.defile();
        }),
      );
    });
  }

  ngOnInit(): void {
    this.updateView();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    log('changes', changes);
    this.clean = true;
    this.updateView();
  }

  onActiveIndexChange(index: number) {
    if (index !== this.activeStepIndex) {
      if (this.activeStepIndex === 0 && this.brandTemplate?.title.trim() === '') {
        this.messageService.add({ severity: 'info', summary: 'Title required', detail: 'Cannot proceed without a template title' });
        return;
      }
      this.activeStepIndex = index;
    }
  }
  updateView() {
    if (this.brandId) {
      this.brandTemplate = this.createEmptyBrandTemplate(this.brandId);
      this.selectedStage = this.brandTemplate.stages[0];
      this.activeStepIndex = 0;
      if (this.templateId && this.templateId != 'new') {
        this.opsService.getBrandTemplate(this.brandId, this.templateId).subscribe((b) => {
          if (Object.keys(b).length === 0) {
            // the service returns an empty object if you make a bad query, or your template was killed
            this.messageService.add({ severity: 'error', summary: 'No template found!', detail: 'Either a technical glitch or someone deleted your template.' });
            return;
          }
          this.brandTemplate = b;
          this.brandTemplate.active = this.brandTemplate.active !== false;
          this.selectedStage = this.brandTemplate.stages?.[0] ?? this.createEmptyStage();
        });
      }
    } else {
      log('no brand, no template');
    }
  }

  selectStage(stage: OpsBrandTemplateStage) {
    if (this.selectedStage === stage) return;
    this.selectedStage = stage;
    const { classList } = this.stageDetails.nativeElement;
    if (classList.contains('animateStageDetails')) {
      classList.replace('animateStageDetails', 'animateStageDetailsAgain');
    } else if (classList.contains('animateStageDetailsAgain')) {
      classList.replace('animateStageDetailsAgain', 'animateStageDetails');
    } else {
      classList.add('animateStageDetails');
    }
  }

  addStage() {
    this.brandTemplate?.stages.push(this.createEmptyStage());
  }

  saveTemplate() {
    if (!this.brandTemplate) return;
    const template = { ...this.brandTemplate };
    template.stages.forEach((p) => this.reorderSteps(p));
    this.opsService.saveBrandTemplate(template).subscribe(({ success, message }) => {
      if (success) {
        this.messageService.add({ severity: 'info', summary: 'Saved', detail: 'Template Saved' });
        this.clean = true;
        this.templateId = '';
        this.updateView();
        this.templateSavedEvent.emit(template);
      } else {
        this.messageService.add({ severity: 'error', summary: 'Save Failed', detail: message });
      }
    });
  }
  deleteTemplate({ brand: brandId, id: templateId }: OpsBrandTemplate) {
    this.opsService.deleteBrandTemplate(brandId, templateId).subscribe(({ success, message }) => {
      if (success) {
        this.messageService.add({ severity: 'info', summary: 'Deleted', detail: message });
        this.templateDeletedEvent.emit(templateId);
        this.clean = true;
      } else {
        this.messageService.add({ severity: 'error', summary: 'Deletion Failed', detail: message });
      }
    });
  }

  deleteStage(event: Event, idx: number) {
    if (!this.brandTemplate) return;
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: `Delete "${this.brandTemplate.stages[idx].name}" and all its tasks?`,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        if (!this.brandTemplate) return;
        this.clean = false;
        this.brandTemplate.stages.splice(idx, 1);
        if (this.brandTemplate.stages.length === 0) {
          this.addStage();
        }
        this.selectStage(this.brandTemplate.stages[0]);
      },
      reject: () => {},
    });
  }

  deleteTeam(event: Event, idx: number) {
    if (!this.brandTemplate) return;
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: `Delete Team "${this.brandTemplate.checkpoint.teams[idx]}" from checkpoint?`,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        if (!this.brandTemplate) return;
        this.brandTemplate.checkpoint.teams.splice(idx, 1);
        this.clean = false;
      },
      reject: () => {},
    });
  }

  deleteMetric(event: Event, idx: number) {
    if (!this.brandTemplate) return;
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: `Delete Metric "${this.brandTemplate.checkpoint.metrics[idx]}" from checkpoint?`,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        if (!this.brandTemplate) return;
        this.brandTemplate.checkpoint.metrics.splice(idx, 1);
        this.clean = false;
      },
      reject: () => {},
    });
  }

  deleteStep(event: Event, idx: number) {
    if (!this.selectedStage) return;
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: `Delete Task "${this.selectedStage.steps[idx].instruction}"?`,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        if (!this.selectedStage) return;
        this.selectedStage.steps.splice(idx, 1);
        this.clean = false;
      },
      reject: () => {},
    });
  }

  createEmptyStep(ordinal = 0): OpsBrandTemplateStep {
    return {
      id: '',
      ordinal,
      team: '',
      instruction: '',
    };
  }
  createEmptyStage(): OpsBrandTemplateStage {
    return {
      id: '',
      ordinal: 0,
      name: 'New Stage',
      steps: [this.createEmptyStep()],
    };
  }

  createEmptyBrandTemplate(brand: string): OpsBrandTemplate {
    return {
      id: 'new',
      brand,
      checkpoint: { teams: [], metrics: [] },
      title: '',
      stages: [this.createEmptyStage()],
      active: true,
    };
  }

  addStep() {
    this.clean = false;
    this.selectedStage?.steps.push(this.createEmptyStep(this.selectedStage.steps.length));
  }

  trackByIndexFn(index: any) {
    return index;
  }

  reorderSteps(stage?: OpsBrandTemplateStage) {
    if (!stage) return;
    stage.steps.forEach((n, idx) => {
      n.ordinal = idx + 1;
    });
  }

  defile() {
    this.clean = false;
  }

  checked(event: CheckboxChangeEvent) {
    // avoid bubbling up to trigger a row select change on chceck.
    event.originalEvent?.stopPropagation();
  }
}
