<div class="game active">
  <div class="gameBody">
    <div class="text-center">
      <!-- title field goes here -->
      <span>{{ this.sampleValue(this.customization.gamePill.title) }}</span>
      <span class="metadataIcon"><i class="pi pi-question-circle"></i></span>
    </div>
  </div>
  <div>
    <div class="grid grid-cols-[14px_1fr_1fr] m-1 leading-4 gap-x-2">
      <ng-container [ngTemplateOutlet]="gamePillLine" [ngTemplateOutletContext]="{ line: this.customization.gamePill.line1 }"></ng-container>
      <ng-container [ngTemplateOutlet]="gamePillLine" [ngTemplateOutletContext]="{ line: this.customization.gamePill.line2 }"></ng-container>
      <ng-container [ngTemplateOutlet]="gamePillLine" [ngTemplateOutletContext]="{ line: this.customization.gamePill.line3 }"></ng-container>
    </div>
  </div>
</div>
<ng-template #gamePillLine let-line="line">
  @if (this.program && line && line.fieldName && line.fieldPath) {
    @switch (line.display) {
      @case ('timedot') {
        <template-editor-sample-pill-signal [eventDate]="this.eventDate" [acknowledged]="this.program.ack[line.fieldPath]"></template-editor-sample-pill-signal>
        <span>{{ line.fieldName }}</span>
        <span class="text-right">__:__ ET</span>
      }
      @case ('time') {
        <span>&nbsp;</span>
        <span>{{ line.fieldName }}</span>
        <span class="text-right">__:__ ET</span>
      }
      @case ('date') {
        <span>&nbsp;</span>
        <span>{{ line.fieldName }}</span>
        <span class="text-right">__/__/__</span>
      }
      <!-- ASSUME: 'string' and any other output is the same -->
      @default {
        <span>&nbsp;</span>
        <span>{{ line.fieldName }}</span>
        <span class="text-right">Value</span>
      }
    }
  }
</ng-template>
