import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ClientConfig } from '@models/ClientConfig';
import { UserProfile } from '@models/UserProfile';
import { OktaAuthStateService } from '@okta/okta-angular';
import { Observable, catchError, combineLatest, distinctUntilChanged, filter, map, shareReplay, startWith, switchMap, throwError, timer } from 'rxjs';
import { environment } from 'src/environments/environment';

const handleError = (error: HttpErrorResponse) => {
  if (error.status === 0) {
    // A client-side or network error occurred. Handle it accordingly.
    console.error('A client-side error occurred (CORS or network blocking):', error.error);
  } else {
    // The backend returned an unsuccessful response code.
    // The response body may contain clues as to what went wrong.
    console.error(`The server returned code ${error.status}, body was: `, error.error);
  }
  // Return an observable with a user-facing error message.
  return throwError(() => new Error('Something bad happened; please try again later.'));
};

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  public CurrentConfig$: Observable<ClientConfig>;
  public CurrentProfile$: Observable<UserProfile>;
  constructor(
    private http: HttpClient,
    private ss: OktaAuthStateService,
  ) {
    const interval$ = timer(0, 30_000);
    const config$ = this.http.get<ClientConfig>(`${environment.glassApiUrl}/v2/config`).pipe(catchError(handleError));
    const userProfile$ = this.http.get<UserProfile>(`${environment.glassApiUrl}/v2/userProfile`).pipe(catchError(handleError));
    const authenticated$ = this.ss.authState$.pipe(
      map((n) => n.isAuthenticated ?? false),
      startWith(false),
      distinctUntilChanged(),
      shareReplay(1),
    );
    const pollingConfig$ = interval$.pipe(switchMap(() => config$));
    const pollingUserProfile$ = interval$.pipe(switchMap(() => userProfile$));

    this.CurrentConfig$ = combineLatest([authenticated$, pollingConfig$]).pipe(
      filter(([authn]) => {
        if (!authn) {
          const error = new Error('Unauthenticated');
          throwError(() => error);
        }
        return authn;
      }),
      map(([, clientConfig]) => clientConfig),
      shareReplay(1),
    );
    this.CurrentProfile$ = combineLatest([authenticated$, pollingUserProfile$]).pipe(
      filter(([authn]) => {
        if (!authn) {
          const error = new Error('Unauthenticated');
          throwError(() => error);
        }
        return authn;
      }),
      map(([, userProfile]) => userProfile),
      shareReplay(1),
    );
  }
}
