import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Brand } from '@models/Brand';
import { OperationResult, TypedOperationResult } from '@models/OperationResult';
import { CheckListUpdateItem, MML_Metadata, OpsBrandEvent, OpsEventCheckpointEntry, OpsEventDay, OpsEventDayProgram, OpsEventDayProgramMessage, OpsEventUser, OpsIncidentHistoryEntry } from '@models/ops.v1/OpsEvents';
import { OpsBrandTemplate } from '@models/ops.v1/OpsTemplate';
import { OpsUser, OpsUserRole } from '@models/ops.v1/OpsUser';
import { Observable, firstValueFrom, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import sampleUsers from './users.static';

export interface UpdateMetadataOptions {
  brand: string;
  eventId: string;
  dayId: string;
  programIndex: number;
  metadata: MML_Metadata;
}

@Injectable({
  providedIn: 'root',
})
export class OpsService {
  public sources: { [key: string]: EventSource } = {};

  constructor(private http: HttpClient) {}

  getUserBrands(): Observable<Array<Brand>> {
    return this.http.get<Brand[]>(`${environment.glassApiUrl}/v1/ops/brands`);
  }

  getUsersForBrand(brand: string): Observable<Record<string, OpsEventUser[]>> {
    return of(sampleUsers);
  }

  saveEvent(newEvent: OpsBrandEvent): Observable<TypedOperationResult<OpsBrandEvent>> {
    return this.http.put<TypedOperationResult<OpsBrandEvent>>(`${environment.glassApiUrl}/v1/ops/${newEvent.brand}/event`, newEvent);
  }

  getStartedEvents(eventId: string, dayId: string) {
    return this.http.get<any[]>(`${environment.glassApiUrl}/v1/ops/startedevents/${eventId}/${dayId}`);
  }

  saveChecklist(opts: any): Observable<TypedOperationResult<OpsEventDay>> {
    return this.http.put<TypedOperationResult<OpsEventDay>>(`${environment.glassApiUrl}/v1/ops/events/${opts.brand}/${opts.eventId}/${opts.dayId}/checklist`, opts.checklist);
  }
  confirmMetadata(opts: any): Observable<TypedOperationResult<OpsEventDay>> {
    return this.http.put<TypedOperationResult<OpsEventDay>>(`${environment.glassApiUrl}/v1/ops/events/${opts.brand}/${opts.eventId}/${opts.dayId}/confirm`, opts.programs);
  }

  updateMetadata(opts: UpdateMetadataOptions): Observable<OperationResult> {
    return this.http.put<OperationResult>(`${environment.glassApiUrl}/v1/ops/events/${opts.brand}/${opts.eventId}/${opts.dayId}/${opts.programIndex}/metadata`, opts.metadata);
  }

  getOpsEventDayProgram(brand: string, eventId: string, dayId: string, programId: string): Observable<OpsEventDayProgram> {
    return this.http.get<OpsEventDayProgram>(`${environment.glassApiUrl}/v1/ops/events/${brand}/${eventId}/${dayId}/${programId}`);
  }

  getOpsEventDay(brand: string, eventId: string, eventDayId: string): Observable<OpsEventDay> {
    return this.http.get<OpsEventDay>(`${environment.glassApiUrl}/v1/ops/events/${brand}/${eventId}/${eventDayId}`);
  }
  getOpsEvent(brand: string, eventId: string): Observable<OpsBrandEvent> {
    return this.http.get<OpsBrandEvent>(`${environment.glassApiUrl}/v1/ops/events/${brand}/${eventId}`);
  }

  getOpsUsers(pageNumber: number, pageSize: number): Observable<OpsUser[]> {
    return this.http.get<OpsUser[]>(`${environment.glassApiUrl}/v1/ops/users?pageNumber=${pageNumber}&pageSize=${pageSize}`);
  }

  getBrandEvents(brand: string): Observable<OpsBrandEvent[]> {
    return this.http.get<OpsBrandEvent[]>(`${environment.glassApiUrl}/v1/ops/events/${brand}`);
  }

  deleteEventDay(brand: string, eventId: string, dayId: string): Observable<TypedOperationResult<boolean>> {
    return this.http.delete<TypedOperationResult<boolean>>(`${environment.glassApiUrl}/v1/ops/events/${brand}/${eventId}/${dayId}`);
  }
  getOpsEvents(): Observable<OpsBrandEvent[]> {
    return this.http.get<OpsBrandEvent[]>(`${environment.glassApiUrl}/v1/ops/allEvents`);
  }

  getBrandTemplates(brandId: string): Observable<OpsBrandTemplate[]> {
    return this.http.get<OpsBrandTemplate[]>(`${environment.glassApiUrl}/v1/ops/template/${brandId}`);
  }

  // retrieve `active` and un-active templates; used for managing templates
  getAllBrandTemplates(brandId: string): Observable<OpsBrandTemplate[]> {
    return this.http.get<OpsBrandTemplate[]>(`${environment.glassApiUrl}/v1/ops/template/${brandId}/all`);
  }

  getBrandTemplate(brandId: string, templateId: string): Observable<OpsBrandTemplate> {
    return this.http.get<OpsBrandTemplate>(`${environment.glassApiUrl}/v1/ops/template/${brandId}/${templateId}`);
  }
  getUserBrandRoles(): Observable<OpsUserRole[]> {
    return this.http.get<OpsUserRole[]>(`${environment.glassApiUrl}/v1/ops/userbrandroles`);
  }

  saveBrandTemplate(template: OpsBrandTemplate): Observable<TypedOperationResult<OpsBrandTemplate>> {
    return this.http.put<TypedOperationResult<OpsBrandTemplate>>(`${environment.glassApiUrl}/v1/ops/template/${template.brand}`, template);
  }

  deleteBrandTemplate(brandId: string, templateId: string): Observable<OperationResult> {
    return this.http.delete<OperationResult>(`${environment.glassApiUrl}/v1/ops/template/${brandId}/${templateId}`);
  }

  completeEventDay(brandId: string, eventId: string, eventDayId: string): Observable<TypedOperationResult<OpsEventDay>> {
    return this.http.put<TypedOperationResult<OpsEventDay>>(`${environment.glassApiUrl}/v1/ops/${brandId}/complete`, { eventId, eventDayId });
  }

  sendCheckListUpdate(brand: string, eventId: string, dayId: string, item: CheckListUpdateItem): void {
    firstValueFrom(this.http.put(`${environment.glassApiUrl}/v1/ops/events/${brand}/${eventId}/${dayId}/checklistUpdate`, item));
  }

  removeOpsEvent(eventId: any): Observable<OperationResult> {
    return this.http.delete<OperationResult>(`${environment.glassApiUrl}/v1/ops/events/${eventId}`);
  }

  addDayToEvent(brand: string, eventId: string, opts: any): Observable<TypedOperationResult<any>> {
    return this.http.put<TypedOperationResult<boolean>>(`${environment.glassApiUrl}/v1/ops/events/${brand}/${eventId}/day`, opts);
  }

  addOrUpdateUser(selectedUser: OpsUser): Observable<TypedOperationResult<OpsUser>> {
    return this.http.put<TypedOperationResult<OpsUser>>(`${environment.glassApiUrl}/v1/ops/user`, { user: selectedUser });
  }

  removeUser(selectedUser: OpsUser): Observable<TypedOperationResult<OpsUser>> {
    return this.http.delete<TypedOperationResult<OpsUser>>(`${environment.glassApiUrl}/v1/ops/user/${selectedUser.id}`);
  }

  addOrUpdateCheckpoint(brand: string, eventId: string, dayId: string, entry: OpsEventCheckpointEntry): Observable<TypedOperationResult<any>> {
    return this.http.put<TypedOperationResult<any>>(`${environment.glassApiUrl}/v1/ops/events/${brand}/${eventId}/${dayId}/checkpoint`, entry);
  }

  addCheckpointNote(brand: string, eventId: string, dayId: string, note: any): Observable<TypedOperationResult<boolean>> {
    return this.http.put<TypedOperationResult<any>>(`${environment.glassApiUrl}/v1/ops/events/${brand}/${eventId}/${dayId}/checkpoint-note`, note);
  }

  addOrUpdateIncident(brand: string, eventId: string, dayId: string, programIndex: string, idx: number, incidentId: string, incident: OpsIncidentHistoryEntry): Observable<TypedOperationResult<any>> {
    return this.http.put<TypedOperationResult<any>>(`${environment.glassApiUrl}/v1/ops/events/${brand}/${eventId}/${dayId}/incident`, { programIndex, incidentId, incident, idx });
  }

  getProgramHistory(brand: string, eventId: string, dayId: string, programId: string): Observable<any> {
    return this.http.get<any>(`${environment.glassApiUrl}/v1/ops/events/${brand}/${eventId}/${dayId}/${programId}/history`);
  }

  addOrUpdateMessage(brandId: string, eventId: string, dayId: string, message: OpsEventDayProgramMessage): Observable<TypedOperationResult<OpsEventDayProgramMessage>> {
    return this.http.put<TypedOperationResult<OpsEventDayProgramMessage>>(`${environment.glassApiUrl}/v1/ops/messages`, { brandId, eventId, dayId, message });
  }

  deleteMessage(brandId: string, eventId: string, dayId: string, messageId: string): Observable<TypedOperationResult<boolean>> {
    return this.http.delete<TypedOperationResult<boolean>>(`${environment.glassApiUrl}/v1/ops/messages/${messageId}`, { params: { brandId, eventId, dayId } });
  }
}
