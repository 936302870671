import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Brand } from '@models/Brand';
import { OperationResult, TypedOperationResult } from '@models/OperationResult';
import { CheckListUpdateItem, OpsBrandEvent, OpsBrandEventTeam, OpsEventCheckpointEntry, OpsEventDay, OpsEventDayProgram, OpsEventDayProgramMessage, OpsEventUser, OpsIncidentHistoryEntry } from '@models/ops.v2/OpsEvents';
import { OpsBrandTemplate } from '@models/ops.v2/OpsTemplate';
import { OpsUser, OpsUserRole } from '@models/ops.v2/OpsUser';
import { MetadataDetail, MetadataField, MetadataRecord } from '@models/ops.v2/Metadata';
import { OpsEventDayProgramStatus } from '@models/ops.v1/OpsEvents';
import { Observable, firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

export interface UpdateMetadataOptions {
  brand: string;
  eventId: string;
  dayId: string;
  programIndex: number;
  metadata?: {
    ack?: any;
    metadata?: any;
    customMetadata?: any;
  };
}

@Injectable({
  providedIn: 'root',
})
export class OpsService {
  public sources: { [key: string]: EventSource } = {};

  constructor(private http: HttpClient) {}

  getUserBrands(): Observable<Array<Brand>> {
    return this.http.get<Brand[]>(`${environment.glassApiUrl}/v2/ops/brands`);
  }

  getUsersForBrand(brand: string): Observable<Array<OpsEventUser>> {
    return this.http.get<Array<OpsEventUser>>(`${environment.glassApiUrl}/v2/ops/${brand}/users`);
  }

  getTeamsForBrand(brand: string): Observable<Array<OpsBrandEventTeam>> {
    return this.http.get<Array<OpsBrandEventTeam>>(`${environment.glassApiUrl}/v2/ops/${brand}/teams`);
  }

  addNewBrandTeam(brand: string): Observable<TypedOperationResult<OpsBrandEventTeam>> {
    return this.http.put<TypedOperationResult<OpsBrandEventTeam>>(`${environment.glassApiUrl}/v2/ops/${brand}/teams`, { brand });
  }

  updateBrandTeam(brand: string, team: OpsBrandEventTeam): Observable<TypedOperationResult<OpsBrandEventTeam>> {
    return this.http.put<TypedOperationResult<OpsBrandEventTeam>>(`${environment.glassApiUrl}/v2/ops/${brand}/teams`, { team });
  }

  deleteBrandTeam(brand: string, id: string): Observable<TypedOperationResult<string>> {
    return this.http.delete<TypedOperationResult<string>>(`${environment.glassApiUrl}/v2/ops/${brand}/teams/${id}`);
  }

  saveEvent(newEvent: OpsBrandEvent): Observable<TypedOperationResult<OpsBrandEvent>> {
    return this.http.put<TypedOperationResult<OpsBrandEvent>>(`${environment.glassApiUrl}/v2/ops/${newEvent.brand}/event`, newEvent);
  }

  getStartedEvents(eventId: string, dayId: string) {
    return this.http.get<any[]>(`${environment.glassApiUrl}/v2/ops/startedevents/${eventId}/${dayId}`);
  }

  saveChecklist(opts: any): Observable<TypedOperationResult<OpsEventDay>> {
    return this.http.put<TypedOperationResult<OpsEventDay>>(`${environment.glassApiUrl}/v2/ops/${opts.brand}/events/${opts.eventId}/${opts.dayId}/checklist`, opts.checklist);
  }
  confirmMetadata(opts: any): Observable<TypedOperationResult<OpsEventDay>> {
    return this.http.put<TypedOperationResult<OpsEventDay>>(`${environment.glassApiUrl}/v2/ops/${opts.brand}/events/${opts.eventId}/${opts.dayId}/confirm`, opts.programs);
  }

  updateMetadata(opts: UpdateMetadataOptions): Observable<OperationResult> {
    return this.http.put<OperationResult>(`${environment.glassApiUrl}/v2/ops/${opts.brand}/events/${opts.eventId}/${opts.dayId}/${opts.programIndex}/metadata`, opts.metadata);
  }

  getOpsEventDayProgram(brand: string, eventId: string, dayId: string, programId: string): Observable<OpsEventDayProgram> {
    return this.http.get<OpsEventDayProgram>(`${environment.glassApiUrl}/v2/ops/${brand}/events/${eventId}/${dayId}/${programId}`);
  }

  getOpsEventDay(brand: string, eventId: string, eventDayId: string): Observable<OpsEventDay> {
    return this.http.get<OpsEventDay>(`${environment.glassApiUrl}/v2/ops/${brand}/events/${eventId}/${eventDayId}`);
  }
  getOpsEvent(brand: string, eventId: string): Observable<OpsBrandEvent> {
    return this.http.get<OpsBrandEvent>(`${environment.glassApiUrl}/v2/ops/${brand}/events/${eventId}`);
  }

  getOpsUsers(pageNumber: number, pageSize: number): Observable<OpsUser[]> {
    return this.http.get<OpsUser[]>(`${environment.glassApiUrl}/v2/ops/users?pageNumber=${pageNumber}&pageSize=${pageSize}`);
  }

  getBrandEvents(brand: string): Observable<OpsBrandEvent[]> {
    return this.http.get<OpsBrandEvent[]>(`${environment.glassApiUrl}/v2/ops/${brand}/events`);
  }

  deleteEventDay(brand: string, eventId: string, dayId: string): Observable<TypedOperationResult<boolean>> {
    return this.http.delete<TypedOperationResult<boolean>>(`${environment.glassApiUrl}/v2/ops/${brand}/events/${eventId}/${dayId}`);
  }
  getOpsEvents(): Observable<OpsBrandEvent[]> {
    return this.http.get<OpsBrandEvent[]>(`${environment.glassApiUrl}/v2/ops/allEvents`);
  }

  getBrandTemplates(brandId: string): Observable<OpsBrandTemplate[]> {
    return this.http.get<OpsBrandTemplate[]>(`${environment.glassApiUrl}/v2/ops/${brandId}/template`);
  }

  // retrieve `active` and un-active templates; used for managing templates
  getAllBrandTemplates(brandId: string): Observable<OpsBrandTemplate[]> {
    return this.http.get<OpsBrandTemplate[]>(`${environment.glassApiUrl}/v2/ops/${brandId}/template/all`);
  }

  getBrandTemplate(brandId: string, templateId: string): Observable<OpsBrandTemplate> {
    return this.http.get<OpsBrandTemplate>(`${environment.glassApiUrl}/v2/ops/${brandId}/template/${templateId}`);
  }
  getUserBrandRoles(): Observable<OpsUserRole[]> {
    return this.http.get<OpsUserRole[]>(`${environment.glassApiUrl}/v2/ops/userbrandroles`);
  }

  saveBrandTemplate(template: OpsBrandTemplate): Observable<TypedOperationResult<OpsBrandTemplate>> {
    return this.http.put<TypedOperationResult<OpsBrandTemplate>>(`${environment.glassApiUrl}/v2/ops/${template.brand}/template`, template);
  }

  deleteBrandTemplate(brandId: string, templateId: string): Observable<OperationResult> {
    return this.http.delete<OperationResult>(`${environment.glassApiUrl}/v2/ops/${brandId}/template/${templateId}`);
  }

  completeEventDay(brandId: string, eventId: string, eventDayId: string): Observable<TypedOperationResult<OpsEventDay>> {
    return this.http.put<TypedOperationResult<OpsEventDay>>(`${environment.glassApiUrl}/v2/ops/${brandId}/complete`, { eventId, eventDayId });
  }

  sendCheckListUpdate(brand: string, eventId: string, dayId: string, item: CheckListUpdateItem): void {
    firstValueFrom(this.http.put(`${environment.glassApiUrl}/v2/ops/${brand}/events/${eventId}/${dayId}/checklistUpdate`, item));
  }

  removeOpsEvent(eventId: any): Observable<OperationResult> {
    return this.http.delete<OperationResult>(`${environment.glassApiUrl}/v2/ops/events/${eventId}`);
  }

  addDayToEvent(brand: string, eventId: string, opts: any): Observable<TypedOperationResult<any>> {
    return this.http.put<TypedOperationResult<boolean>>(`${environment.glassApiUrl}/v2/ops/${brand}/events/${eventId}/day`, opts);
  }

  addOrUpdateUser(selectedUser: OpsUser): Observable<TypedOperationResult<OpsUser>> {
    return this.http.put<TypedOperationResult<OpsUser>>(`${environment.glassApiUrl}/v2/ops/user`, { user: selectedUser });
  }

  removeUser(selectedUser: OpsUser): Observable<TypedOperationResult<OpsUser>> {
    return this.http.delete<TypedOperationResult<OpsUser>>(`${environment.glassApiUrl}/v2/ops/user/${selectedUser.id}`);
  }

  addOrUpdateCheckpoint(brand: string, eventId: string, dayId: string, entry: OpsEventCheckpointEntry): Observable<TypedOperationResult<any>> {
    return this.http.put<TypedOperationResult<any>>(`${environment.glassApiUrl}/v2/ops/${brand}/events/${eventId}/${dayId}/checkpoint`, entry);
  }

  addCheckpointNote(brand: string, eventId: string, dayId: string, note: any): Observable<TypedOperationResult<boolean>> {
    return this.http.put<TypedOperationResult<any>>(`${environment.glassApiUrl}/v2/ops/${brand}/events/${eventId}/${dayId}/checkpoint-note`, note);
  }

  addOrUpdateIncident(brand: string, eventId: string, dayId: string, programIndex: string, idx: number, incidentId: string, incident: OpsIncidentHistoryEntry): Observable<TypedOperationResult<any>> {
    return this.http.put<TypedOperationResult<any>>(`${environment.glassApiUrl}/v2/ops/${brand}/events/${eventId}/${dayId}/incident`, { programIndex, incidentId, incident, idx });
  }

  getProgramHistory(brand: string, eventId: string, dayId: string, programId: string): Observable<any> {
    return this.http.get<any>(`${environment.glassApiUrl}/v2/ops/${brand}/events/${eventId}/${dayId}/${programId}/history`);
  }

  getMetadataSources(): Observable<any> {
    return this.http.get<any>(`${environment.glassApiUrl}/v1/admin/metadataSources`);
  }

  updateMetadataSource(update: MetadataRecord): Observable<TypedOperationResult<MetadataRecord>> {
    return this.http.put<any>(`${environment.glassApiUrl}/v1/admin/metadataSources`, update);
  }
  addMetadataFieldForSource(_id: string, newFieldName: string, newFieldPaths: string, transforms: string) {
    const newField = {
      jsonPaths: newFieldPaths,
      fieldName: newFieldName,
      transforms: transforms,
    };
    return this.http.put<TypedOperationResult<MetadataDetail>>(`${environment.glassApiUrl}/v1/admin/metadataSource/${_id}/field`, newField);
  }
  updateMetadataFieldForSource(_id: string, updatedRecord: MetadataDetail): Observable<TypedOperationResult<MetadataDetail>> {
    return this.http.put<TypedOperationResult<MetadataDetail>>(`${environment.glassApiUrl}/v1/admin/metadataSource/${_id}/field`, updatedRecord);
  }
  getMetadataFieldsForSource(_id: string): Observable<MetadataDetail[]> {
    return this.http.get<MetadataDetail[]>(`${environment.glassApiUrl}/v1/admin/metadataSource/${_id}/fields`);
  }
  getMetadataForEventType(type: string): Observable<MetadataField[]> {
    console.debug(`event type: ${type}`);
    return this.http.get<MetadataField[]>(`${environment.glassApiUrl}/v1/admin/eventSource/${type}/fields`);
  }

  addOrUpdateMessage(brandId: string, eventId: string, dayId: string, message: OpsEventDayProgramMessage): Observable<TypedOperationResult<OpsEventDayProgramMessage>> {
    return this.http.put<TypedOperationResult<OpsEventDayProgramMessage>>(`${environment.glassApiUrl}/v2/ops/${brandId}/events/${eventId}/${dayId}/messages`, message);
  }

  deleteMessage(brandId: string, eventId: string, dayId: string, messageId: string): Observable<TypedOperationResult<boolean>> {
    return this.http.delete<TypedOperationResult<boolean>>(`${environment.glassApiUrl}/v2/ops/${brandId}/events/${eventId}/${dayId}/messages/${messageId}`);
  }

  updateProgramStatus(brandId: string, eventId: string, dayId: string, programIndex: number, status: OpsEventDayProgramStatus): Observable<TypedOperationResult<boolean>> {
    return this.http.put<TypedOperationResult<boolean>>(`${environment.glassApiUrl}/v2/ops/${brandId}/events/${eventId}/${dayId}/updateStatus`, { programIndex, status });
  }
}
