import { NgModule } from '@angular/core';
import { OKTA_CONFIG, OktaAuthModule } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedComponentsModule } from '@shared/components/shared-components.module';
import { FormsModule } from '@angular/forms';
import { ConfigService } from '@services/config.service';
import { VideoService } from '@services/video.service';
import { DragulaModule } from 'ng2-dragula';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ContentLayoutComponent } from './layout/content-layout/content-layout.component';
import { primeng_modules } from './shared/primeng-imports';
import { DialogModule } from 'primeng/dialog';
import { environment as env } from './../environments/environment';
import { okta } from '../../src/environments/okta';
import { AuthInterceptor } from './core/interceptor/auth';

import { InplaceModule } from 'primeng/inplace';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import adapter from 'webrtc-adapter';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
declare global {
  interface Window {
    TOP: any;
    players: any;
    readonly document: Document;
    adapter: any;
    YT: any;
  }
}

const oktaAuth = new OktaAuth({
  issuer: env.oktaIssuer,
  clientId: okta.clientId,
  redirectUri: env.loginCallback,
  logoutUrl: env.logoutCallback,
  scopes: ['openid', 'email', 'groups', 'profile'],
});

@NgModule({
  declarations: [AppComponent, ContentLayoutComponent],
  imports: [BrowserModule, BrowserAnimationsModule, FormsModule, HttpClientModule, AppRoutingModule, primeng_modules, OktaAuthModule, DialogModule, SharedComponentsModule, DragulaModule.forRoot(), InplaceModule, FontAwesomeModule],
  providers: [ConfigService, VideoService, { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }, { provide: OKTA_CONFIG, useValue: { oktaAuth } }],
  bootstrap: [AppComponent],
})
export class AppModule {}
