import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ClientConfig } from '@models/ClientConfig';
import { UserProfile } from '@models/UserProfile';
import { OktaAuthStateService } from '@okta/okta-angular';
import { Observable, catchError, combineLatest, distinctUntilChanged, map, repeat, retry, shareReplay, startWith, tap, throwError, timer } from 'rxjs';
import { environment } from 'src/environments/environment';

const handleError = (error: HttpErrorResponse) => {
  if (error.status === 0) {
    // A client-side or network error occurred. Handle it accordingly.
    console.error('A client-side error occurred (CORS or network blocking):', error.error);
  } else {
    // The backend returned an unsuccessful response code.
    // The response body may contain clues as to what went wrong.
    console.error(`The server returned code ${error.status}, body was: `, error.error);
  }
  // Return an observable with a user-facing error message.
  return throwError(() => new Error('Something bad happened; please try again later.'));
};
const logTag = '[config-service]';
const logMsg = console.debug.bind(console, logTag);
const logErr = console.error.bind(console, logTag);

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  public CurrentConfig$: Observable<ClientConfig>;
  public CurrentProfile$: Observable<UserProfile>;
  constructor(
    private http: HttpClient,
    private ss: OktaAuthStateService,
  ) {
    const config$ = this.http.get<ClientConfig>(`${environment.glassApiUrl}/v2/config`).pipe(
      repeat({ delay: 30_000 }),
      retry({
        count: 2,
        delay: (error, count) => {
          return timer(Math.min(60_000, (2 ^ count) * 1000));
        },
      }),
      catchError(handleError),
      shareReplay({ bufferSize: 1, refCount: true, windowTime: 30_000 }),
    );
    const userProfile$ = this.http.get<UserProfile>(`${environment.glassApiUrl}/v2/userProfile`).pipe(
      repeat({ delay: 30_000 }),
      retry({
        count: 2,
        delay: (error, count) => {
          return timer(Math.min(60_000, (2 ^ count) * 1000));
        },
      }),
      catchError(handleError),
      shareReplay({ bufferSize: 1, refCount: true, windowTime: 30_000 }),
    );
    const authenticated$ = this.ss.authState$.pipe(
      map((n) => n.isAuthenticated ?? false),
      startWith(false),
      // tap((authState) => {
      //   logMsg('authN?', authState);
      // }),
      distinctUntilChanged(),
      shareReplay(1),
    );

    this.CurrentConfig$ = combineLatest([authenticated$, config$]).pipe(
      tap(([authN, clientConfig]) => {
        // logMsg('[authN, clientConfig]', [authN, clientConfig]);
        if (!authN) {
          const error = new Error('Unauthenticated');
          throwError(() => error);
        }
      }),
      map(([, clientConfig]) => clientConfig),
      shareReplay({ bufferSize: 1, refCount: true, windowTime: 30_000 }),
    );
    this.CurrentProfile$ = combineLatest([authenticated$, userProfile$]).pipe(
      tap(([authN, userProfile]) => {
        // logMsg('[authN, userProfile]', [authN, userProfile]);
        if (!authN) {
          const error = new Error('Unauthenticated');
          throwError(() => error);
        }
      }),
      map(([, userProfile]) => userProfile),
      shareReplay({ bufferSize: 1, refCount: true, windowTime: 30_000 }),
    );
  }
}
