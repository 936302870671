<div class="customFieldContainer">
  <div class="flex flex-row items-center w-full">
    <div class="flex w-[25%] m-1">Field</div>
    <div class="flex w-[75%] m-1">
      <p-checkbox [(ngModel)]="this.inCollection" (onChange)="updateCheckedStatus($event)" [binary]="true" [label]="field.fieldName" />
    </div>
  </div>
  <div class="flex flex-row items-center w-full">
    <div class="flex w-[25%] m-1">Override Name</div>
    <div class="flex w-[75%] m-1">
      <input pInputText type="text" placeholder="Enter custom display name" class="w-full min-w-0" [disabled]="!this.inCollection" (blur)="updateCheckedStatus()" [(ngModel)]="overrideName" />
    </div>
  </div>
</div>
